// TODO: REFACTORING
import React, { useEffect } from 'react';
import * as Styled from './FinishProposalCreated.styles';
import { translate as t } from '@utils/translate';
import BankTransferContent from '@components/BankTransferContent';

function FinishProposalCreated({ onFinish, onCheckout, paymentInstructions }) {
  const TEXTS = {
    SUCCESS_TITLE: t('createdSuccessTitle'),
    CREDIT_CARD_INSTRUCTIONS: t('creditCardInstruction'),
    GOTO_CHECKOUT_LABEL: t('goToCheckoutText'),
  };

  useEffect(() => {
    //console.log(paymentInstructions);
  }, [paymentInstructions]);

  const CreditCardContent = ({}) => (
    <>
      <Styled.Title>{TEXTS.SUCCESS_TITLE}</Styled.Title>
      <Styled.Description>{TEXTS.CREDIT_CARD_INSTRUCTIONS}</Styled.Description>

      <Styled.ActionWrapper>
        <Styled.Button onClick={onCheckout}>
          {TEXTS.GOTO_CHECKOUT_LABEL}
        </Styled.Button>
      </Styled.ActionWrapper>
    </>
  );

  return (
    <Styled.ProposalCreated>
      {paymentInstructions.paymentTypeKey === 'BankTransfer' ? (
        <BankTransferContent
          onFinish={onFinish}
          instructions={paymentInstructions.wireTransferSetup}
        />
      ) : (
        <CreditCardContent />
      )}
    </Styled.ProposalCreated>
  );
}

export default FinishProposalCreated;
