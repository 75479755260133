// TODO: REFACTORING
import React, { useState, useEffect } from 'react';
import * as Styled from './AircraftInfo.style';

import AircraftCharacteristics from '@components/AircraftCharacteristics';
import AircraftComfort from '@components/AircraftComfort';

import { translate as t } from '@utils/translate';
import aircraftModelNameHelper from '@utils/aircraftModelNameHelper';

const AircraftInfo = ({ aircraftId, aircraftsDetails }) => {
  const [amenities, setAmenities] = useState([]);
  const [characteristics, setCharacteristics] = useState([]);
  const [showMoreComfort, setShowMoreComfort] = useState(false);
  const TEXTS = {
    SEE_MORE: t('seeMore'),
    AVAILABLE_BOTH: t('availableAtCharterAndShared'),
    AVAILABLE_SHARED: t('availableAtShared'),
    AVAILABLE_CHARTER: t('availableAtCharter'),
    AIRCRAFT_INFORMATION: t('aircraftInfo'),
    GENERAL_DETAILS: t('generalDetails'),
    TITLE_COMFORT: t('comfort'),
  };

  const mapItemsAmenities = (amenities = []) => {
    return amenities.map(item => {
      const availability = item.availability;
      let description = '';
      if (
        availability.isAvailableForCharter === 1 &&
        availability.isAvailableForShared === 1
      )
        description = TEXTS.AVAILABLE_BOTH;
      else if (availability.isAvailableForCharter === 1)
        description = TEXTS.AVAILABLE_CHARTER;
      else if (availability.isAvailableForShared === 1)
        description = TEXTS.AVAILABLE_SHARED;

      return {
        icon: item.iconImageUrl,
        title: item.name,
        description: description,
      };
    });
  };

  const mapItemsCharacteristics = aircraft => {
    const model = aircraft.model;
    const data = [
      {
        key: 'numberOfPassengers',
        value: aircraft.quantityOfPassengers || model.quantityOfPassengers,
      },
      { key: 'numberOfPilots', value: model.quantityOfPilots },
      { key: 'numberOfLuggage', value: model.quantityOfSuitcases },
      { key: 'luggageRackVolume', value: 0 },
      { key: 'cruiseSpeed', value: aircraft.cruiseSpeed || model.cruiseSpeed },
      { key: 'maximumPayload', value: model.payloadLimit },
      { key: 'maximumRange', value: model.maxRange },
    ];
    return data.map(item => {
      return {
        type: item.key,
        value: item.value,
      };
    });
  };

  useEffect(() => {
    if (
      aircraftsDetails[aircraftId] &&
      aircraftsDetails[aircraftId].amenities
    ) {
      const mappedAmenities = mapItemsAmenities(
        aircraftsDetails[aircraftId].amenities
      );
      const mapperCharacteristics = mapItemsCharacteristics(
        aircraftsDetails[aircraftId]
      );
      setAmenities(mappedAmenities);
      setCharacteristics(mapperCharacteristics);
    }
  }, [aircraftsDetails]);

  const handleShowMoreComfort = () => {
    setShowMoreComfort(true);
  };

  if (!aircraftsDetails) return null;

  return (
    <Styled.AircraftInfo showMoreComfort={showMoreComfort}>
      <h2 className={'aircraft-info__title'}>{TEXTS.AIRCRAFT_INFORMATION}</h2>
      <div className={'aircraft-info__description'}>
        <h4>{aircraftModelNameHelper(aircraftsDetails[aircraftId].model)}</h4>
        <p>{aircraftsDetails[aircraftId].model.description}</p>
      </div>
      <div className={'aircraft-info__inner-details'}>
        <Styled.SectionTitle>{TEXTS.GENERAL_DETAILS}</Styled.SectionTitle>
        {characteristics.length > 0 && (
          <div className={'general-details'}>
            <div className={'general-details--image'}>
              <img
                src={
                  aircraftsDetails[aircraftId].seatMapImageUrl ||
                  aircraftsDetails[aircraftId].model.seatMapImageUrl
                }
              />
            </div>
            <div className={'general-details--characteristics'}>
              <AircraftCharacteristics title={''} items={characteristics} />
            </div>
          </div>
        )}

        {amenities.length > 0 && (
          <>
            <Styled.SectionTitle>{TEXTS.TITLE_COMFORT}</Styled.SectionTitle>
            <div className={'comfort'}>
              <AircraftComfort items={amenities} title={''} />
              {!showMoreComfort && amenities.length >= 5 && (
                <>
                  <span
                    className={'see-more-button'}
                    onClick={handleShowMoreComfort}
                  >
                    {TEXTS.SEE_MORE}
                  </span>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </Styled.AircraftInfo>
  );
};

export default AircraftInfo;
