import styled from 'styled-components';

const UserAddress = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  font-size: 10px;
  justify-content: center;
  margin-right: 34em;
  margin-top: 7em;
`;

const SavedAddressWrapper = styled.div`
  width: 30em;
  margin-right: 4em;
`;

export { UserAddress, SavedAddressWrapper };
