import styled from 'styled-components';

export const Agreement = styled.div`
  padding: 20px;
`;

export const SectionTitle = styled.h2`
  font-size: 26px;
  font-weight: bold;
  color: #212121;
`;

export const TextArea = styled.iframe`
  width: 100%;
  border: 2px solid #ddd;
  background-color: white;
  min-height: 400px;

  @media (max-width: 720px) {
    min-height: auto;
    height: 100%;
  }
`;
