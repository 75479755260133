// TODO: REFACTORING
import React from 'react';
import LoginStep from '@components/LoginStep';
import * as Styled from './FinishProposalLogin.styles';
import { translate as t } from '@utils/translate';

function FinishProposalLogin({ onLogin }) {
  const TEXTS = {
    LOGIN_TITLE: t('loginTitle'),
    LOGIN_SUBTITLE: t('loginSubtitle'),
  };

  function handleLogin(data) {
    onLogin(data);
  }

  return (
    <Styled.Login>
      <Styled.InnerWrapper>
        <Styled.Header>
          <h1>{TEXTS.LOGIN_TITLE}</h1>
          <h2>{TEXTS.LOGIN_SUBTITLE}</h2>
        </Styled.Header>
        <LoginStep
          hideActions
          redirectTo={'/checkout'}
          onChange={handleLogin}
        />
      </Styled.InnerWrapper>
    </Styled.Login>
  );
}

export default FinishProposalLogin;
