// Third party libs/components
import React from 'react';

// Components
import * as Styled from './HiddenFlightLabels.styles';
import FlightLabelDetails from '../FlightLabelsDetails';

const HiddenFlightLabels = ({ items, maxVisibleLabels }) => {
  return (
    <Styled.HiddenFlightLabelsWrapper>
      {items.map((item, index) => {
        const labelData = {
          aircraft: item.aircraft.name,
          origin: item.originSpot.name,
          destination: item.destinationSpot.name,
          departure: item.departureDatetime,
          flightNumber: index + maxVisibleLabels,
        };

        return <FlightLabelDetails item={labelData} />;
      })}
    </Styled.HiddenFlightLabelsWrapper>
  );
};

export default HiddenFlightLabels;
