import styled, { keyframes, css } from 'styled-components';
import { COLORS } from '@utils/styles/colors';

export const Warning = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 84px;
  width: 100%;

  padding: 20px;
  margin: 20px 0;
  border-radius: 8px;

  background: transparent
    linear-gradient(
      90deg,
      ${COLORS.CELADON_GREEN} 0%,
      ${COLORS.PERSIAN_GREEN} 100%
    )
    0% 0% no-repeat padding-box;
  color: white;
  font-size: 21px;
  font-weight: 500;

  &:before {
    content: '!';
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    background-color: white;
    color: ${COLORS.PERSIAN_GREEN};
    font-weight: bold;
  }

  @media (max-width: 720px) {
    padding: 15px;
    font-size: 13px !important;
    font-weight: 500;

    &:before {
      display: none;
    }
  }
`;
