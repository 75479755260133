import styled, { css } from 'styled-components';
import { switchProp, ifNotProp } from 'styled-tools';
import { COLORS } from '@utils/styles/colors';

export const AircraftInfo = styled.div`
  /* padding: 0 20px 0 20px; */

  .aircraft-info__title {
    font-size: 26px;
    color: #212121;
  }

  .aircraft-info__description {
    h4 {
      font-size: 30px;
      color: #757575;
      font-weight: 500;
    }

    p {
      font-size: 17px;
      text-align: justify;
      color: #757575;
    }
  }

  .aircraft-info__inner-details {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;

    transition: all ease 1s;

    section {
      margin-bottom: 0;
    }

    section > h2 {
      display: none;
    }

    h2 {
      margin: 30px 0 0 0;
      padding-bottom: 10px;
      border-bottom: 1px solid #ddd;
      color: #212121;
    }

    .see-more-button {
      cursor: pointer;
      color: ${COLORS.PERSIAN_GREEN};
      width: 100%;
      text-align: center;
      border-bottom: 1px solid #e5e5e5;
      padding-bottom: 10px;
    }

    .comfort {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      margin-top: 20px;
      width: 100%;

      img {
        max-width: 50px !important;
      }

      ${ifNotProp(
        'showMoreComfort',
        css`
          div:nth-child(n + 5) {
            display: none;
          }
        `
      )}
    }

    .general-details {
      display: flex;
      flex: 1;
      margin-top: 20px;

      > img {
        max-width: 50%;
      }

      > div {
        margin: 0 auto;
        width: 35%;
      }
    }
  }

  @media (max-width: 720px) {
    padding: 0;
    padding-right: 0px;

    .aircraft-info__title {
      font-size: 16px;
    }

    .aircraft-info__description {
      h4 {
        font-size: 20px;
      }

      p {
        font-size: 16px;
        padding: 5px;
      }
    }

    .aircraft-info__inner-details {
      flex-direction: column;
      justify-content: center;

      .comfort {
        width: 100%;

        img {
          max-width: 30px !important;
        }

        section > div > div {
          font-size: 14px;
          display: flex;
        }
      }

      .general-details {
        display: flex;
        flex-direction: column;
        flex: 1;

        > img {
          max-width: 100%;
        }

        > div {
          width: 100%;
        }
      }
    }
  }
`;

export const SectionTitle = styled.h2`
  font-size: 26px;
  font-weight: bold;
  color: #212121;
`;
