// TODO: REFACTORING
import React from 'react';
import * as Styled from './Price.style';

const Price = ({ values, currencies }) => {
  return (
    <>
      <Styled.PriceWrapper>
        {values.operator ? (
          <div>
            <span className={'price--main'}>
              {values.operator} <em>{currencies.operator}</em>
            </span>
            <span className={'price--secondary'}>
              (<em>est</em> <strong>{values.current}</strong>{' '}
              <em>{currencies.current}</em>)
            </span>
          </div>
        ) : (
          <div className={'price--main'}>
            <span>
              {values.current} {currencies.current}
            </span>
          </div>
        )}
      </Styled.PriceWrapper>
    </>
  );
};

export default Price;
