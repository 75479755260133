// TODO: REFACTORING
import React from 'react';
import * as Styled from './Arrow.style';

const Arrow = ({ direction = 'right', color = 'black' }) => {
  return (
    <Styled.Arrow direction={direction} color={color} className={'arrow'}>
      ➔
    </Styled.Arrow>
  );
};

export default Arrow;
