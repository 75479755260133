import styled, { css } from 'styled-components';

const handleDirection = direction => {
  switch (direction) {
    case 'right':
      return css`
        transform: rotate(0deg);
      `;
    case 'down':
      return css`
        transform: rotate(90deg);
      `;
    case 'left':
      return css`
        transform: rotate(180deg);
      `;
    case 'top':
      return css`
        transform: rotate(270deg);
      `;
  }
};

export const Arrow = styled.div`
  margin: 0 10px;

  ${({ direction }) => handleDirection(direction)};
  ${({ color }) => `color: ${color}`};
`;
