import styled from 'styled-components';

export const HiddenFlightLabelsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  > * {
    flex: 1 1 160px;
  }

  > div {
    margin-top: -10px;
  }
`;
