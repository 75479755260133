// TODO: REFACTORING
import React, { useState } from 'react';
import { useEffect } from 'react';
import getCurrencyList from '@services/getCurrencyList';
import CreditCardInput from '@components/CreditCardInput';
import { useCurrentLang, translate as t } from '@utils/translate';
import * as Styled from './FinishProposalPaymentType.styles';

const ALLOWED_PAYMENT_TYPES = ['BankTransfer', 'CreditCard'];

function FinishProposalPaymentType({ onChoose, initialData, methodsAllowed }) {
  // TODO TRANSLATIONS
  const TEXTS = {
    METHOD_TITLE: t('paymentMethodTitle'),
    METHOD_SUBTITLE: t('paymentMethodSubtitle'),
    TRANSFER_CURRENCY_LABEL: t('transferSelectCurrency'),
    SELECT_LABEL: t('selectSomething'),
  };
  const language = useCurrentLang();
  const [paymentId, setPaymentId] = useState(null);
  const [currencyList, setCurrencyList] = useState(null);
  const [isCurrencyVisible, setIsCurrencyVisible] = useState(false);
  const [currency, setCurrency] = useState('BRL');

  useEffect(() => {
    if (typeof initialData === 'number') {
      setPaymentId(initialData);
    }
  }, [initialData]);

  useEffect(() => {
    setCurrencyList(getCurrencyList(language));
  }, [language]);

  function handlePaymentChange(id) {
    setPaymentId(id);
    onChoose({ id, currency });
    setIsCurrencyVisible(id === 1);
  }

  function handleCurrencyChange(evt) {
    setCurrency(evt.target.value);
    onChoose({ id: paymentId, currency: evt.target.value });
  }

  return (
    <Styled.PaymentType>
      <Styled.Title>{TEXTS.METHOD_TITLE}</Styled.Title>

      <Styled.Description>
        <p>{TEXTS.METHOD_SUBTITLE}</p>
      </Styled.Description>

      <Styled.ActionsWrapper>
        {methodsAllowed.map(method => {
          return ALLOWED_PAYMENT_TYPES.indexOf(method.paymentTypeKey) !== -1 ? (
            <>
              <Styled.ActionButton
                type="button"
                onClick={() => handlePaymentChange(method.id)}
                selected={paymentId === method.id}
                key={method.id}
              >
                <span>{method.name}</span>
              </Styled.ActionButton>
            </>
          ) : (
            <></>
          );
        })}
      </Styled.ActionsWrapper>

      <Styled.CurrencySelector isVisible={isCurrencyVisible}>
        <Styled.Description marginBottom="5px">
          <p>{TEXTS.TRANSFER_CURRENCY_LABEL}</p>
        </Styled.Description>

        <CreditCardInput customStyle={{ left: '-5px' }}>
          <select
            name="currency"
            tabIndex="2"
            onChange={evt => handleCurrencyChange(evt)}
          >
            <option defaultValue="">{TEXTS.SELECT_LABEL}</option>
            {currencyList &&
              currencyList.map(({ code, description }) => (
                <option value={code} key={code}>
                  {description}
                </option>
              ))}
          </select>
        </CreditCardInput>
      </Styled.CurrencySelector>
    </Styled.PaymentType>
  );
}

export default FinishProposalPaymentType;
