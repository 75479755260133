const PROPOSAL = {
  quotationProposal: {
    id: 310,
    hash: '207e6566f04d7058d49d8727847c26a75890915a261a82fe107c3da03856a33f',
    legalAgreementId: 1,
    statusId: 3,
    expiryDatetime: '2020-10-15 21:18:47',
    dynamicLink:
      'http://qa3.flyflapper.com/proposals/207e6566f04d7058d49d8727847c26a75890915a261a82fe107c3da03856a33f?lang=en',
    client: 'Android;4.4.0',
    currency: 'BRL',
    showOperatorCurrency: 1,
    requestDocumentForInvoice: 1,
    observations: null,
    createDatetime: '2020-09-15 21:18:47',
    updateDatetime: '2020-09-16 10:09:16',
    languageId: 2,
    status: {
      id: 3,
      name: 'Pending Payment',
    },
    language: 'en_US',
    legalAgreement: {
      id: 1,
      typeId: 1,
      name: 'Charter - Terms of Service',
      documentUrl:
        'https://flappermedia.s3.amazonaws.com/chartercontract/charter-terms-pt_BR.html',
      version: '1.0',
      isActive: 1,
    },
    urlDocument:
      'http://qa3.flyflapper.com/api/proposal/generatedocument?id=310',
    paymentMethodsAllowed: [
      {
        id: 1,
        paymentTypeKey: 'BankTransfer',
        name: 'Wire transfer',
        description: '',
        isActive: 1,
        relevance: 1,
        createDatetime: '2020-01-07 02:20:44',
        updateDatetime: null,
      },
      {
        id: 2,
        paymentTypeKey: 'CreditCard',
        name: 'Credit card',
        description: '',
        isActive: 1,
        relevance: 1,
        createDatetime: '2020-01-07 02:20:44',
        updateDatetime: null,
      },
    ],
    charterContract: {
      id: 56,
      quotationProposalItemId: 346,
      invoiceId: 2161,
      legalAgreementId: 1,
      totalPrice: 3750,
      currency: 'BRL',
      createDatetime: '2020-09-16 10:09:16',
      updateDatetime: '2020-09-16 10:09:16',
      isActive: 1,
      cityManagerId: null,
      isSigned: 0,
      signedAt: null,
      paymentMethodSelectedId: 1,
      languageId: 2,
      userPaymentCurrency: 'brl',
      additionalComments: null,
      quotationProposalId: 310,
      language: 'en_US',
      urlDocument:
        'http://qa3.flyflapper.com/api/quotation/chartercontract/generatedocument?id=56',
      legalAgreement: {
        id: 1,
        typeId: 1,
        name: 'Charter - Terms of Service',
        documentUrl:
          'https://flappermedia.s3.amazonaws.com/chartercontract/charter-terms-pt_BR.html',
        version: '1.0',
        isActive: 1,
      },
      cityManager: null,
      invoice: {
        id: 2161,
        orderId: null,
        addressId: 24428,
        fullName: 'Sérgio Sampaio',
        documentTypeId: 1,
        documentNumber: '',
        totalAmount: 3750,
        currency: 'BRL',
        isActive: 1,
        url: '1',
        address: {
          id: 24428,
          cityId: null,
          zipCode: '37715305',
          address: 'Vista da Colina',
          complement: '',
          number: '80',
          neighborhood: null,
          latitude: null,
          longitude: null,
          createDatetime: '2020-09-16 10:09:16',
          updateDatetime: '2020-09-16 10:09:16',
          name: 'Casa',
          line: 'Vista da Colina, 80, Poços de Caldas - MG, BR, 37715305',
          isDefault: 0,
          cityName: 'Poços de Caldas',
          stateName: 'MG',
          countryName: 'BR',
        },
      },
      paymentMethodSelected: {
        paymentTypeKey: 'BankTransfer',
        name: 'Wire transfer',
        bankAccounts: [],
        wireTransferSetup: {
          benefiaciaryAccount: {
            name: 'Flapper Technologies LTDA',
            registrationNumber: '027.028.507/0001-00',
            address: 'Rua Vitor Barbosa, 63',
            iban: 'BR0833923798000000027028507C1',
            showIban: '1',
            showAccount: '0',
            showSwift: '1',
            showAgency: '0',
            bankAccounts: [
              {
                agency: '',
                accountNumber: '',
                swift: 'BMAXBRRJ',
                bank: {
                  name: 'Banco Maxinvest S.A.',
                  number: 'M12',
                  imageUrl: null,
                },
              },
            ],
          },
          intermediaryInstitutions: {
            showAccount: '0',
            showSwift: '1',
            showAgency: '0',
            bankAccounts: [
              {
                agency: '',
                accountNumber: '',
                swift: 'SCBLDEFX',
                bank: {
                  name: 'STANDARD CHARTERED BANK ',
                  number: null,
                  imageUrl: null,
                },
              },
            ],
          },
        },
      },
    },
    owner: {
      id: 104396,
      ownerTypeId: 1,
      name: 'Sérgio Sampaio',
      emailAddress: 's.sampaio@flapper.aero',
    },
    events: [
      {
        id: 376,
        quotationProposalId: 310,
        eventTypeId: 2,
        message: null,
        createActorTypeId: 1,
        createActorId: 104396,
        createDatetime: '2020-09-15 21:18:47',
        updateDatetime: '2020-09-15 21:18:47',
        statusChange: {
          quotationProposalEventLogId: 376,
          fromStatusId: null,
          toStatusId: 1,
        },
      },
      {
        id: 379,
        quotationProposalId: 310,
        eventTypeId: 2,
        message: null,
        createActorTypeId: 2,
        createActorId: 32,
        createDatetime: '2020-09-16 10:01:52',
        updateDatetime: '2020-09-16 10:01:52',
        statusChange: {
          quotationProposalEventLogId: 379,
          fromStatusId: 1,
          toStatusId: 2,
        },
      },
      {
        id: 380,
        quotationProposalId: 310,
        eventTypeId: 2,
        message: null,
        createActorTypeId: null,
        createActorId: null,
        createDatetime: '2020-09-16 10:09:16',
        updateDatetime: '2020-09-16 10:09:16',
        statusChange: {
          quotationProposalEventLogId: 380,
          fromStatusId: 2,
          toStatusId: 3,
        },
      },
    ],
    signers: [],
    items: [
      {
        id: 346,
        quotationProposalId: 310,
        totalAmount: 3750,
        totalAmountAdjusted: 3750,
        initialCurrency: 'BRL',
        currency: 'BRL',
        observations: null,
        isSelected: 1,
        isActive: 1,
        createDatetime: '2020-09-15 21:18:47',
        updateDatetime: '2020-09-16 10:09:16',
        segments: [
          {
            id: 1565,
            quotationProposalItemId: 346,
            flightId: null,
            aircraftId: 27,
            originSpotId: 906,
            destinationSpotId: 110,
            distance: 19.58,
            departureDatetime: '2020-09-15 21:17:00',
            estimatedTime: 11,
            estimatedArrivalDatetime: '2020-09-15 21:28:00',
            price: 1160,
            fboCost: 550,
            airFeeCost: 0,
            airExtraCost: 0,
            displacementCost: 527.5,
            overnightStayCost: 0,
            commission: 1.15,
            emptyLeg: 1,
            isLegacy: 0,
            isActive: 1,
            createDatetime: '2020-09-15 21:18:47',
            updateDatetime: '2020-09-15 21:18:47',
            originSpot: {
              id: 906,
              name: 'Aeroporto Campo de Marte',
              shortName: null,
              phoneNumber: '(11) 2223-3700',
              contactFullName: null,
              email: null,
              addressId: 910,
              nearByRange: 10,
              imageUrl:
                'https://dlwwkvaei5hfp.cloudfront.net/urban+helipads/sp/campo_de_marte.jpg',
              isVisibleToUser: 1,
              isActive: 1,
              createDatetime: '2017-05-05 13:51:14',
              updateDatetime: '2019-09-12 15:03:39',
              address: {
                id: 910,
                cityId: 1,
                zipCode: '02022-011',
                address: 'Av. Olavo Fontoura',
                complement: null,
                number: '950',
                neighborhood: 'Santana',
                latitude: -23.509794,
                longitude: -46.640337,
                createDatetime: '2017-05-05 13:51:14',
                updateDatetime: '2019-09-12 15:03:39',
                name: null,
                line: 'Av. Olavo Fontoura, 950',
                isDefault: 0,
                cityName: null,
                stateName: null,
                countryName: null,
                city: {
                  id: 1,
                  stateId: 1,
                  name: 'São Paulo',
                  description: null,
                  imageUrl:
                    'https://dlwwkvaei5hfp.cloudfront.net/route_destinations/sao_paulo.jpg',
                  iata: 'SAO',
                  relevance: 99,
                  tagGroupId: null,
                  timeZoneId: null,
                  state: {
                    id: 1,
                    countryId: 32,
                    name: 'São Paulo',
                    acronym: 'SP',
                    relevance: 10,
                    country: {
                      id: 32,
                      name: 'Brasil',
                      relevance: 10,
                      abreviation: 'BRA',
                      iso2Code: null,
                      iso3Code: null,
                    },
                  },
                },
              },
              airport: {
                id: 3,
                spotId: 906,
                oaci: 'SBMT',
                isActive: 1,
                acronym: 'CDM',
                landingCost: null,
                costPerHour: null,
                airstripLength: 1600,
                airstripWidth: 45,
                overnightStayCost: 1500,
                overnightStayCostCurrency: 'BRL',
                openingTime: '06:00:00',
                closingTime: '00:00:00',
                savingTimeAdd: '01:00:00',
                airstripTypeId: 1,
              },
              helipad: {
                id: 906,
                spotId: 906,
                helipadTypeId: 11,
                helipadStructureId: 1,
                oaci: 'SBMT',
                isActive: 1,
                acronym: 'CDM',
                landingCost: null,
                costPerHour: null,
                openingTime: '00:00:00',
                closingTime: null,
                savingTimeAdd: '01:00:00',
                airstripTypeId: 1,
                isRelativeForQuotation: 1,
              },
            },
            destinationSpot: {
              id: 110,
              name: 'Helicenter Alphaville',
              shortName: null,
              phoneNumber: null,
              contactFullName: null,
              email: null,
              addressId: 114,
              nearByRange: 10,
              imageUrl:
                'https://dlwwkvaei5hfp.cloudfront.net/urban+helipads/sp/Helicenter+Alphaville.png',
              isVisibleToUser: 1,
              isActive: 1,
              createDatetime: '2017-05-05 13:48:06',
              updateDatetime: '2020-01-15 19:24:29',
              address: {
                id: 114,
                cityId: 1,
                zipCode: '06543-312',
                address: 'Alameda Ásia',
                complement: 'Barueri - Santana de Parnaíba',
                number: '298',
                neighborhood: 'Centro Empresarial Tamboré',
                latitude: -23.475,
                longitude: -46.828333,
                createDatetime: '2017-05-05 13:48:06',
                updateDatetime: '2020-01-15 19:24:29',
                name: null,
                line: 'Alameda Ásia, 298, Barueri',
                isDefault: 0,
                cityName: null,
                stateName: null,
                countryName: null,
                city: {
                  id: 1,
                  stateId: 1,
                  name: 'São Paulo',
                  description: null,
                  imageUrl:
                    'https://dlwwkvaei5hfp.cloudfront.net/route_destinations/sao_paulo.jpg',
                  iata: 'SAO',
                  relevance: 99,
                  tagGroupId: null,
                  timeZoneId: null,
                  state: {
                    id: 1,
                    countryId: 32,
                    name: 'São Paulo',
                    acronym: 'SP',
                    relevance: 10,
                    country: {
                      id: 32,
                      name: 'Brasil',
                      relevance: 10,
                      abreviation: 'BRA',
                      iso2Code: null,
                      iso3Code: null,
                    },
                  },
                },
              },
              helipad: {
                id: 109,
                spotId: 110,
                helipadTypeId: 13,
                helipadStructureId: 1,
                oaci: 'SJIW',
                isActive: 1,
                acronym: null,
                landingCost: 0,
                costPerHour: null,
                openingTime: '06:00:00',
                closingTime: '22:00:00',
                savingTimeAdd: '01:00:00',
                airstripTypeId: 9,
                isRelativeForQuotation: 1,
              },
            },
            aircraft: {
              id: 27,
              aircraftModelId: 68,
              name: 'Robinson R44 ',
              quantityOfPassengers: 3,
              registrationNumber: 'PR-PGC',
              registrationCategory: null,
              serialNumber: null,
              ownerCompanyId: 8,
              baseSpotId: 906,
              paintingColor: null,
              paintingYear: 2007,
              baggageInfo: null,
              costPerKilometer: 0,
              minPrice: 0,
              costPerHour: 2500,
              currency: null,
              cruiseSpeed: null,
              slope: null,
              intercept: null,
              minimumDistance: 0,
              imageUrl:
                'https://dlwwkvaei5hfp.cloudfront.net/aircrafts/1524086749472136_image.png',
              highlightImageUrl:
                'https://dlwwkvaei5hfp.cloudfront.net/aircrafts/1584818977126823_image.jpg',
              engineStartupTime: null,
              instantBooking: 0,
              refurbishedAt: null,
              seatMapImageUrl:
                'https://dlwwkvaei5hfp.cloudfront.net/aircrafts/1531331363523420_seats.png',
              isActive: 1,
              createDatetime: '2017-05-06 15:05:53',
              updateDatetime: '2020-03-21 16:29:37',
              quotationFormulaId: 2,
              baseSpot: {
                id: 906,
                name: 'Aeroporto Campo de Marte',
                shortName: null,
                phoneNumber: '(11) 2223-3700',
                contactFullName: null,
                email: null,
                addressId: 910,
                nearByRange: 10,
                imageUrl:
                  'https://dlwwkvaei5hfp.cloudfront.net/urban+helipads/sp/campo_de_marte.jpg',
                isVisibleToUser: 1,
                isActive: 1,
                createDatetime: '2017-05-05 13:51:14',
                updateDatetime: '2019-09-12 15:03:39',
                address: {
                  id: 910,
                  cityId: 1,
                  zipCode: '02022-011',
                  address: 'Av. Olavo Fontoura',
                  complement: null,
                  number: '950',
                  neighborhood: 'Santana',
                  latitude: -23.509794,
                  longitude: -46.640337,
                  createDatetime: '2017-05-05 13:51:14',
                  updateDatetime: '2019-09-12 15:03:39',
                  name: null,
                  line: 'Av. Olavo Fontoura, 950',
                  isDefault: 0,
                  cityName: null,
                  stateName: null,
                  countryName: null,
                  city: {
                    id: 1,
                    stateId: 1,
                    name: 'São Paulo',
                    description: null,
                    imageUrl:
                      'https://dlwwkvaei5hfp.cloudfront.net/route_destinations/sao_paulo.jpg',
                    iata: 'SAO',
                    relevance: 99,
                    tagGroupId: null,
                    timeZoneId: null,
                    state: {
                      id: 1,
                      countryId: 32,
                      name: 'São Paulo',
                      acronym: 'SP',
                      relevance: 10,
                    },
                  },
                },
              },
              model: {
                id: 68,
                manufacturer: 'Robinson',
                manufacturerId: 1,
                model: 'R44 II',
                aircraftTypeId: 1,
                propulsionTypeId: 4,
                payloadLimit: null,
                quantityOfPassengers: 3,
                quantityOfPilots: 1,
                quantityOfSuitcases: 3,
                cabinHeight: null,
                baggageInfo: '1 bagagem de mão, de até 10kg, por pessoa.',
                maxSpeed: 240,
                cruiseSpeed: 185,
                maxRange: 550,
                minVisibility: null,
                description:
                  'O Robinson 44 é uma excelente aeronave de passeio. A aeronave oferece visibilidade desobstruída para todos os passageiros. Graças ao seu tamanho compacto e silenciador de alta capacidade, o R44 é surpreendentemente silencioso e proporciona uma experiência de voo suave.\n\nA versão mais recente Raven II possui um sistema de rotor reprojetado e injeção eletrônica de combustível. Isso garante melhor aerodinâmica e mais potência em altas altitudes (por exemplo cruzando Serra do Mar no Litoral Paulista).',
                rate: 5,
                fliesOnLowVisibility: 0,
                minimumAirstripeLength: 0,
                minimumAirstripeWidth: 0,
                sbCost: null,
                dCost: 0,
                fFactorValue: null,
                fFactorCostType: null,
                seatMapImageUrl:
                  'https://dlwwkvaei5hfp.cloudfront.net/aircrafts/models/1530734766999815_seats.png',
                isActive: 1,
                imageUrl:
                  'https://dlwwkvaei5hfp.cloudfront.net/aircrafts/models/r44_image.png',
                highlightImageUrl:
                  'https://dlwwkvaei5hfp.cloudfront.net/aircrafts/models/r44_02_highlight.jpg',
                engineStartupTime: 5,
                minRepositionTime: null,
                createDatetime: '2018-03-29 15:09:56',
                updateDatetime: '2020-02-19 09:53:13',
                aircraftSizeId: 14,
                propulsionType: {
                  id: 4,
                  name: 'Rotor system',
                  description: 'Rotor system',
                  createDatetime: '2017-08-17 16:06:00',
                  updateDatetime: '2017-08-17 16:06:00',
                },
              },
            },
          },
          {
            id: 1566,
            quotationProposalItemId: 346,
            flightId: null,
            aircraftId: 27,
            originSpotId: 110,
            destinationSpotId: 1285,
            distance: 60.73,
            departureDatetime: '2020-09-15 21:17:00',
            estimatedTime: 25,
            estimatedArrivalDatetime: '2020-09-15 21:42:00',
            price: 1198,
            fboCost: 0,
            airFeeCost: 0,
            airExtraCost: 0,
            displacementCost: 1198,
            overnightStayCost: 0,
            commission: 1.15,
            emptyLeg: 0,
            isLegacy: 0,
            isActive: 1,
            createDatetime: '2020-09-15 21:18:47',
            updateDatetime: '2020-09-15 21:18:47',
            originSpot: {
              id: 110,
              name: 'Helicenter Alphaville',
              shortName: null,
              phoneNumber: null,
              contactFullName: null,
              email: null,
              addressId: 114,
              nearByRange: 10,
              imageUrl:
                'https://dlwwkvaei5hfp.cloudfront.net/urban+helipads/sp/Helicenter+Alphaville.png',
              isVisibleToUser: 1,
              isActive: 1,
              createDatetime: '2017-05-05 13:48:06',
              updateDatetime: '2020-01-15 19:24:29',
              address: {
                id: 114,
                cityId: 1,
                zipCode: '06543-312',
                address: 'Alameda Ásia',
                complement: 'Barueri - Santana de Parnaíba',
                number: '298',
                neighborhood: 'Centro Empresarial Tamboré',
                latitude: -23.475,
                longitude: -46.828333,
                createDatetime: '2017-05-05 13:48:06',
                updateDatetime: '2020-01-15 19:24:29',
                name: null,
                line: 'Alameda Ásia, 298, Barueri',
                isDefault: 0,
                cityName: null,
                stateName: null,
                countryName: null,
                city: {
                  id: 1,
                  stateId: 1,
                  name: 'São Paulo',
                  description: null,
                  imageUrl:
                    'https://dlwwkvaei5hfp.cloudfront.net/route_destinations/sao_paulo.jpg',
                  iata: 'SAO',
                  relevance: 99,
                  tagGroupId: null,
                  timeZoneId: null,
                  state: {
                    id: 1,
                    countryId: 32,
                    name: 'São Paulo',
                    acronym: 'SP',
                    relevance: 10,
                    country: {
                      id: 32,
                      name: 'Brasil',
                      relevance: 10,
                      abreviation: 'BRA',
                      iso2Code: null,
                      iso3Code: null,
                    },
                  },
                },
              },
              helipad: {
                id: 109,
                spotId: 110,
                helipadTypeId: 13,
                helipadStructureId: 1,
                oaci: 'SJIW',
                isActive: 1,
                acronym: null,
                landingCost: 0,
                costPerHour: null,
                openingTime: '06:00:00',
                closingTime: '22:00:00',
                savingTimeAdd: '01:00:00',
                airstripTypeId: 9,
                isRelativeForQuotation: 1,
              },
            },
            destinationSpot: {
              id: 1285,
              name: 'Aeroporto de Viracopos',
              shortName: null,
              phoneNumber: '+55193725.5000',
              contactFullName: null,
              email: null,
              addressId: 1289,
              nearByRange: 10,
              imageUrl: null,
              isVisibleToUser: 1,
              isActive: 1,
              createDatetime: '2017-06-15 19:34:52',
              updateDatetime: '2019-11-06 17:35:55',
              address: {
                id: 1289,
                cityId: 2,
                zipCode: '13055-900',
                address: 'Rodovia Santos Dumont',
                complement: 'Aeroporto Internacional de Viracopos ',
                number: 'km 66',
                neighborhood: 'Parque Viracopos',
                latitude: -23.009667,
                longitude: -47.138256,
                createDatetime: '2017-06-15 19:34:52',
                updateDatetime: '2019-11-06 17:35:55',
                name: null,
                line: null,
                isDefault: 0,
                cityName: null,
                stateName: null,
                countryName: null,
                city: {
                  id: 2,
                  stateId: 1,
                  name: 'Campinas',
                  description: null,
                  imageUrl:
                    'https://dlwwkvaei5hfp.cloudfront.net/cities/1560464655935888.jpg',
                  iata: 'CAM',
                  relevance: 0,
                  tagGroupId: null,
                  timeZoneId: null,
                  state: {
                    id: 1,
                    countryId: 32,
                    name: 'São Paulo',
                    acronym: 'SP',
                    relevance: 10,
                    country: {
                      id: 32,
                      name: 'Brasil',
                      relevance: 10,
                      abreviation: 'BRA',
                      iso2Code: null,
                      iso3Code: null,
                    },
                  },
                },
              },
              airport: {
                id: 377,
                spotId: 1285,
                oaci: 'SBKP',
                isActive: 1,
                acronym: 'VCP',
                landingCost: 0,
                costPerHour: 0,
                airstripLength: 3240,
                airstripWidth: 45,
                overnightStayCost: 1500,
                overnightStayCostCurrency: 'BRL',
                openingTime: '00:00:00',
                closingTime: null,
                savingTimeAdd: '01:00:00',
                airstripTypeId: 1,
              },
            },
            aircraft: {
              id: 27,
              aircraftModelId: 68,
              name: 'Robinson R44 ',
              quantityOfPassengers: 3,
              registrationNumber: 'PR-PGC',
              registrationCategory: null,
              serialNumber: null,
              ownerCompanyId: 8,
              baseSpotId: 906,
              paintingColor: null,
              paintingYear: 2007,
              baggageInfo: null,
              costPerKilometer: 0,
              minPrice: 0,
              costPerHour: 2500,
              currency: null,
              cruiseSpeed: null,
              slope: null,
              intercept: null,
              minimumDistance: 0,
              imageUrl:
                'https://dlwwkvaei5hfp.cloudfront.net/aircrafts/1524086749472136_image.png',
              highlightImageUrl:
                'https://dlwwkvaei5hfp.cloudfront.net/aircrafts/1584818977126823_image.jpg',
              engineStartupTime: null,
              instantBooking: 0,
              refurbishedAt: null,
              seatMapImageUrl:
                'https://dlwwkvaei5hfp.cloudfront.net/aircrafts/1531331363523420_seats.png',
              isActive: 1,
              createDatetime: '2017-05-06 15:05:53',
              updateDatetime: '2020-03-21 16:29:37',
              quotationFormulaId: 2,
              baseSpot: {
                id: 906,
                name: 'Aeroporto Campo de Marte',
                shortName: null,
                phoneNumber: '(11) 2223-3700',
                contactFullName: null,
                email: null,
                addressId: 910,
                nearByRange: 10,
                imageUrl:
                  'https://dlwwkvaei5hfp.cloudfront.net/urban+helipads/sp/campo_de_marte.jpg',
                isVisibleToUser: 1,
                isActive: 1,
                createDatetime: '2017-05-05 13:51:14',
                updateDatetime: '2019-09-12 15:03:39',
                address: {
                  id: 910,
                  cityId: 1,
                  zipCode: '02022-011',
                  address: 'Av. Olavo Fontoura',
                  complement: null,
                  number: '950',
                  neighborhood: 'Santana',
                  latitude: -23.509794,
                  longitude: -46.640337,
                  createDatetime: '2017-05-05 13:51:14',
                  updateDatetime: '2019-09-12 15:03:39',
                  name: null,
                  line: 'Av. Olavo Fontoura, 950',
                  isDefault: 0,
                  cityName: null,
                  stateName: null,
                  countryName: null,
                  city: {
                    id: 1,
                    stateId: 1,
                    name: 'São Paulo',
                    description: null,
                    imageUrl:
                      'https://dlwwkvaei5hfp.cloudfront.net/route_destinations/sao_paulo.jpg',
                    iata: 'SAO',
                    relevance: 99,
                    tagGroupId: null,
                    timeZoneId: null,
                    state: {
                      id: 1,
                      countryId: 32,
                      name: 'São Paulo',
                      acronym: 'SP',
                      relevance: 10,
                    },
                  },
                },
              },
              model: {
                id: 68,
                manufacturer: 'Robinson',
                manufacturerId: 1,
                model: 'R44 II',
                aircraftTypeId: 1,
                propulsionTypeId: 4,
                payloadLimit: null,
                quantityOfPassengers: 3,
                quantityOfPilots: 1,
                quantityOfSuitcases: 3,
                cabinHeight: null,
                baggageInfo: '1 bagagem de mão, de até 10kg, por pessoa.',
                maxSpeed: 240,
                cruiseSpeed: 185,
                maxRange: 550,
                minVisibility: null,
                description:
                  'O Robinson 44 é uma excelente aeronave de passeio. A aeronave oferece visibilidade desobstruída para todos os passageiros. Graças ao seu tamanho compacto e silenciador de alta capacidade, o R44 é surpreendentemente silencioso e proporciona uma experiência de voo suave.\n\nA versão mais recente Raven II possui um sistema de rotor reprojetado e injeção eletrônica de combustível. Isso garante melhor aerodinâmica e mais potência em altas altitudes (por exemplo cruzando Serra do Mar no Litoral Paulista).',
                rate: 5,
                fliesOnLowVisibility: 0,
                minimumAirstripeLength: 0,
                minimumAirstripeWidth: 0,
                sbCost: null,
                dCost: 0,
                fFactorValue: null,
                fFactorCostType: null,
                seatMapImageUrl:
                  'https://dlwwkvaei5hfp.cloudfront.net/aircrafts/models/1530734766999815_seats.png',
                isActive: 1,
                imageUrl:
                  'https://dlwwkvaei5hfp.cloudfront.net/aircrafts/models/r44_image.png',
                highlightImageUrl:
                  'https://dlwwkvaei5hfp.cloudfront.net/aircrafts/models/r44_02_highlight.jpg',
                engineStartupTime: 5,
                minRepositionTime: null,
                createDatetime: '2018-03-29 15:09:56',
                updateDatetime: '2020-02-19 09:53:13',
                aircraftSizeId: 14,
                propulsionType: {
                  id: 4,
                  name: 'Rotor system',
                  description: 'Rotor system',
                  createDatetime: '2017-08-17 16:06:00',
                  updateDatetime: '2017-08-17 16:06:00',
                },
              },
            },
          },
          {
            id: 1567,
            quotationProposalItemId: 346,
            flightId: null,
            aircraftId: 27,
            originSpotId: 1285,
            destinationSpotId: 906,
            distance: 75.45,
            departureDatetime: '2020-09-15 21:17:00',
            estimatedTime: 29,
            estimatedArrivalDatetime: '2020-09-15 21:46:00',
            price: 1390,
            fboCost: 0,
            airFeeCost: 0,
            airExtraCost: 0,
            displacementCost: 1390,
            overnightStayCost: 0,
            commission: 1.15,
            emptyLeg: 1,
            isLegacy: 0,
            isActive: 1,
            createDatetime: '2020-09-15 21:18:47',
            updateDatetime: '2020-09-15 21:18:47',
            originSpot: {
              id: 1285,
              name: 'Aeroporto de Viracopos',
              shortName: null,
              phoneNumber: '+55193725.5000',
              contactFullName: null,
              email: null,
              addressId: 1289,
              nearByRange: 10,
              imageUrl: null,
              isVisibleToUser: 1,
              isActive: 1,
              createDatetime: '2017-06-15 19:34:52',
              updateDatetime: '2019-11-06 17:35:55',
              address: {
                id: 1289,
                cityId: 2,
                zipCode: '13055-900',
                address: 'Rodovia Santos Dumont',
                complement: 'Aeroporto Internacional de Viracopos ',
                number: 'km 66',
                neighborhood: 'Parque Viracopos',
                latitude: -23.009667,
                longitude: -47.138256,
                createDatetime: '2017-06-15 19:34:52',
                updateDatetime: '2019-11-06 17:35:55',
                name: null,
                line: null,
                isDefault: 0,
                cityName: null,
                stateName: null,
                countryName: null,
                city: {
                  id: 2,
                  stateId: 1,
                  name: 'Campinas',
                  description: null,
                  imageUrl:
                    'https://dlwwkvaei5hfp.cloudfront.net/cities/1560464655935888.jpg',
                  iata: 'CAM',
                  relevance: 0,
                  tagGroupId: null,
                  timeZoneId: null,
                  state: {
                    id: 1,
                    countryId: 32,
                    name: 'São Paulo',
                    acronym: 'SP',
                    relevance: 10,
                    country: {
                      id: 32,
                      name: 'Brasil',
                      relevance: 10,
                      abreviation: 'BRA',
                      iso2Code: null,
                      iso3Code: null,
                    },
                  },
                },
              },
              airport: {
                id: 377,
                spotId: 1285,
                oaci: 'SBKP',
                isActive: 1,
                acronym: 'VCP',
                landingCost: 0,
                costPerHour: 0,
                airstripLength: 3240,
                airstripWidth: 45,
                overnightStayCost: 1500,
                overnightStayCostCurrency: 'BRL',
                openingTime: '00:00:00',
                closingTime: null,
                savingTimeAdd: '01:00:00',
                airstripTypeId: 1,
              },
            },
            destinationSpot: {
              id: 906,
              name: 'Aeroporto Campo de Marte',
              shortName: null,
              phoneNumber: '(11) 2223-3700',
              contactFullName: null,
              email: null,
              addressId: 910,
              nearByRange: 10,
              imageUrl:
                'https://dlwwkvaei5hfp.cloudfront.net/urban+helipads/sp/campo_de_marte.jpg',
              isVisibleToUser: 1,
              isActive: 1,
              createDatetime: '2017-05-05 13:51:14',
              updateDatetime: '2019-09-12 15:03:39',
              address: {
                id: 910,
                cityId: 1,
                zipCode: '02022-011',
                address: 'Av. Olavo Fontoura',
                complement: null,
                number: '950',
                neighborhood: 'Santana',
                latitude: -23.509794,
                longitude: -46.640337,
                createDatetime: '2017-05-05 13:51:14',
                updateDatetime: '2019-09-12 15:03:39',
                name: null,
                line: 'Av. Olavo Fontoura, 950',
                isDefault: 0,
                cityName: null,
                stateName: null,
                countryName: null,
                city: {
                  id: 1,
                  stateId: 1,
                  name: 'São Paulo',
                  description: null,
                  imageUrl:
                    'https://dlwwkvaei5hfp.cloudfront.net/route_destinations/sao_paulo.jpg',
                  iata: 'SAO',
                  relevance: 99,
                  tagGroupId: null,
                  timeZoneId: null,
                  state: {
                    id: 1,
                    countryId: 32,
                    name: 'São Paulo',
                    acronym: 'SP',
                    relevance: 10,
                    country: {
                      id: 32,
                      name: 'Brasil',
                      relevance: 10,
                      abreviation: 'BRA',
                      iso2Code: null,
                      iso3Code: null,
                    },
                  },
                },
              },
              airport: {
                id: 3,
                spotId: 906,
                oaci: 'SBMT',
                isActive: 1,
                acronym: 'CDM',
                landingCost: null,
                costPerHour: null,
                airstripLength: 1600,
                airstripWidth: 45,
                overnightStayCost: 1500,
                overnightStayCostCurrency: 'BRL',
                openingTime: '06:00:00',
                closingTime: '00:00:00',
                savingTimeAdd: '01:00:00',
                airstripTypeId: 1,
              },
              helipad: {
                id: 906,
                spotId: 906,
                helipadTypeId: 11,
                helipadStructureId: 1,
                oaci: 'SBMT',
                isActive: 1,
                acronym: 'CDM',
                landingCost: null,
                costPerHour: null,
                openingTime: '00:00:00',
                closingTime: null,
                savingTimeAdd: '01:00:00',
                airstripTypeId: 1,
                isRelativeForQuotation: 1,
              },
            },
            aircraft: {
              id: 27,
              aircraftModelId: 68,
              name: 'Robinson R44 ',
              quantityOfPassengers: 3,
              registrationNumber: 'PR-PGC',
              registrationCategory: null,
              serialNumber: null,
              ownerCompanyId: 8,
              baseSpotId: 906,
              paintingColor: null,
              paintingYear: 2007,
              baggageInfo: null,
              costPerKilometer: 0,
              minPrice: 0,
              costPerHour: 2500,
              currency: null,
              cruiseSpeed: null,
              slope: null,
              intercept: null,
              minimumDistance: 0,
              imageUrl:
                'https://dlwwkvaei5hfp.cloudfront.net/aircrafts/1524086749472136_image.png',
              highlightImageUrl:
                'https://dlwwkvaei5hfp.cloudfront.net/aircrafts/1584818977126823_image.jpg',
              engineStartupTime: null,
              instantBooking: 0,
              refurbishedAt: null,
              seatMapImageUrl:
                'https://dlwwkvaei5hfp.cloudfront.net/aircrafts/1531331363523420_seats.png',
              isActive: 1,
              createDatetime: '2017-05-06 15:05:53',
              updateDatetime: '2020-03-21 16:29:37',
              quotationFormulaId: 2,
              baseSpot: {
                id: 906,
                name: 'Aeroporto Campo de Marte',
                shortName: null,
                phoneNumber: '(11) 2223-3700',
                contactFullName: null,
                email: null,
                addressId: 910,
                nearByRange: 10,
                imageUrl:
                  'https://dlwwkvaei5hfp.cloudfront.net/urban+helipads/sp/campo_de_marte.jpg',
                isVisibleToUser: 1,
                isActive: 1,
                createDatetime: '2017-05-05 13:51:14',
                updateDatetime: '2019-09-12 15:03:39',
                address: {
                  id: 910,
                  cityId: 1,
                  zipCode: '02022-011',
                  address: 'Av. Olavo Fontoura',
                  complement: null,
                  number: '950',
                  neighborhood: 'Santana',
                  latitude: -23.509794,
                  longitude: -46.640337,
                  createDatetime: '2017-05-05 13:51:14',
                  updateDatetime: '2019-09-12 15:03:39',
                  name: null,
                  line: 'Av. Olavo Fontoura, 950',
                  isDefault: 0,
                  cityName: null,
                  stateName: null,
                  countryName: null,
                  city: {
                    id: 1,
                    stateId: 1,
                    name: 'São Paulo',
                    description: null,
                    imageUrl:
                      'https://dlwwkvaei5hfp.cloudfront.net/route_destinations/sao_paulo.jpg',
                    iata: 'SAO',
                    relevance: 99,
                    tagGroupId: null,
                    timeZoneId: null,
                    state: {
                      id: 1,
                      countryId: 32,
                      name: 'São Paulo',
                      acronym: 'SP',
                      relevance: 10,
                    },
                  },
                },
              },
              model: {
                id: 68,
                manufacturer: 'Robinson',
                manufacturerId: 1,
                model: 'R44 II',
                aircraftTypeId: 1,
                propulsionTypeId: 4,
                payloadLimit: null,
                quantityOfPassengers: 3,
                quantityOfPilots: 1,
                quantityOfSuitcases: 3,
                cabinHeight: null,
                baggageInfo: '1 bagagem de mão, de até 10kg, por pessoa.',
                maxSpeed: 240,
                cruiseSpeed: 185,
                maxRange: 550,
                minVisibility: null,
                description:
                  'O Robinson 44 é uma excelente aeronave de passeio. A aeronave oferece visibilidade desobstruída para todos os passageiros. Graças ao seu tamanho compacto e silenciador de alta capacidade, o R44 é surpreendentemente silencioso e proporciona uma experiência de voo suave.\n\nA versão mais recente Raven II possui um sistema de rotor reprojetado e injeção eletrônica de combustível. Isso garante melhor aerodinâmica e mais potência em altas altitudes (por exemplo cruzando Serra do Mar no Litoral Paulista).',
                rate: 5,
                fliesOnLowVisibility: 0,
                minimumAirstripeLength: 0,
                minimumAirstripeWidth: 0,
                sbCost: null,
                dCost: 0,
                fFactorValue: null,
                fFactorCostType: null,
                seatMapImageUrl:
                  'https://dlwwkvaei5hfp.cloudfront.net/aircrafts/models/1530734766999815_seats.png',
                isActive: 1,
                imageUrl:
                  'https://dlwwkvaei5hfp.cloudfront.net/aircrafts/models/r44_image.png',
                highlightImageUrl:
                  'https://dlwwkvaei5hfp.cloudfront.net/aircrafts/models/r44_02_highlight.jpg',
                engineStartupTime: 5,
                minRepositionTime: null,
                createDatetime: '2018-03-29 15:09:56',
                updateDatetime: '2020-02-19 09:53:13',
                aircraftSizeId: 14,
                propulsionType: {
                  id: 4,
                  name: 'Rotor system',
                  description: 'Rotor system',
                  createDatetime: '2017-08-17 16:06:00',
                  updateDatetime: '2017-08-17 16:06:00',
                },
              },
            },
          },
        ],
        localTotalAmount: 3750,
        localTotalAmountAdjusted: 3750,
        localCurrency: 'BRL',
      },
    ],
  },
  cart: {
    token: '2b9278f4026414e55f3a94a694fdea013639b29f0b8762130d413133927ff139',
    products: [
      {
        id: '4',
        typeId: '4',
        price: '3750.00',
        data: {
          segments: [
            {
              originSpot: {
                id: '906',
                name: 'Aeroporto Campo de Marte',
                address: {
                  id: '910',
                  cityId: '1',
                  zipCode: '02022-011',
                  address: 'Av. Olavo Fontoura',
                  complement: null,
                  number: '950',
                  neighborhood: 'Santana',
                  latitude: '-23.50979400',
                  longitude: '-46.64033700',
                  createDatetime: '2017-05-05 13:51:14',
                  updateDatetime: '2019-09-12 15:03:39',
                  name: null,
                  line: 'Av. Olavo Fontoura, 950',
                  isDefault: '0',
                  cityName: null,
                  stateName: null,
                  countryName: null,
                },
                airport: {
                  oaci: 'SBMT',
                  acronym: 'CDM',
                },
                helipad: {
                  oaci: 'SBMT',
                },
              },
              destinationSpot: {
                id: '110',
                name: 'Helicenter Alphaville',
                address: {
                  id: '114',
                  cityId: '1',
                  zipCode: '06543-312',
                  address: 'Alameda Ásia',
                  complement: 'Barueri - Santana de Parnaíba',
                  number: '298',
                  neighborhood: 'Centro Empresarial Tamboré',
                  latitude: '-23.47500000',
                  longitude: '-46.82833300',
                  createDatetime: '2017-05-05 13:48:06',
                  updateDatetime: '2020-01-15 19:24:29',
                  name: null,
                  line: 'Alameda Ásia, 298, Barueri',
                  isDefault: '0',
                  cityName: null,
                  stateName: null,
                  countryName: null,
                },
                helipad: {
                  oaci: 'SJIW',
                },
              },
              aircraft: {
                id: '27',
                highlightImageUrl:
                  'https://dlwwkvaei5hfp.cloudfront.net/aircrafts/1584818977126823_image.jpg',
                quantityOfPassengers: '3',
                cruiseSpeed: '185',
                model: {
                  id: '68',
                  manufacturer: 'Robinson',
                  model: 'R44 II',
                  highlightImageUrl:
                    'https://dlwwkvaei5hfp.cloudfront.net/aircrafts/models/r44_02_highlight.jpg',
                },
              },
              departureDatetime: '2020-09-15T21:17:00-03:00',
              estimatedArrivalDatetime: '2020-09-15T21:28:00-03:00',
              estimatedTime: '11',
              emptyLeg: '1',
              price: '1160.00',
            },
            {
              originSpot: {
                id: '110',
                name: 'Helicenter Alphaville',
                address: {
                  id: '114',
                  cityId: '1',
                  zipCode: '06543-312',
                  address: 'Alameda Ásia',
                  complement: 'Barueri - Santana de Parnaíba',
                  number: '298',
                  neighborhood: 'Centro Empresarial Tamboré',
                  latitude: '-23.47500000',
                  longitude: '-46.82833300',
                  createDatetime: '2017-05-05 13:48:06',
                  updateDatetime: '2020-01-15 19:24:29',
                  name: null,
                  line: 'Alameda Ásia, 298, Barueri',
                  isDefault: '0',
                  cityName: null,
                  stateName: null,
                  countryName: null,
                },
                helipad: {
                  oaci: 'SJIW',
                },
              },
              destinationSpot: {
                id: '1285',
                name: 'Aeroporto de Viracopos',
                address: {
                  id: '1289',
                  cityId: '2',
                  zipCode: '13055-900',
                  address: 'Rodovia Santos Dumont',
                  complement: 'Aeroporto Internacional de Viracopos ',
                  number: 'km 66',
                  neighborhood: 'Parque Viracopos',
                  latitude: '-23.00966700',
                  longitude: '-47.13825600',
                  createDatetime: '2017-06-15 19:34:52',
                  updateDatetime: '2019-11-06 17:35:55',
                  name: null,
                  line: null,
                  isDefault: '0',
                  cityName: null,
                  stateName: null,
                  countryName: null,
                },
                airport: {
                  oaci: 'SBKP',
                  acronym: 'VCP',
                },
              },
              aircraft: {
                id: '27',
                highlightImageUrl:
                  'https://dlwwkvaei5hfp.cloudfront.net/aircrafts/1584818977126823_image.jpg',
                quantityOfPassengers: '3',
                cruiseSpeed: '185',
                model: {
                  id: '68',
                  manufacturer: 'Robinson',
                  model: 'R44 II',
                  highlightImageUrl:
                    'https://dlwwkvaei5hfp.cloudfront.net/aircrafts/models/r44_02_highlight.jpg',
                },
              },
              departureDatetime: '2020-09-15T21:17:00-03:00',
              estimatedArrivalDatetime: '2020-09-15T21:42:00-03:00',
              estimatedTime: '25',
              emptyLeg: '0',
              price: '1198.00',
            },
            {
              originSpot: {
                id: '1285',
                name: 'Aeroporto de Viracopos',
                address: {
                  id: '1289',
                  cityId: '2',
                  zipCode: '13055-900',
                  address: 'Rodovia Santos Dumont',
                  complement: 'Aeroporto Internacional de Viracopos ',
                  number: 'km 66',
                  neighborhood: 'Parque Viracopos',
                  latitude: '-23.00966700',
                  longitude: '-47.13825600',
                  createDatetime: '2017-06-15 19:34:52',
                  updateDatetime: '2019-11-06 17:35:55',
                  name: null,
                  line: null,
                  isDefault: '0',
                  cityName: null,
                  stateName: null,
                  countryName: null,
                },
                airport: {
                  oaci: 'SBKP',
                  acronym: 'VCP',
                },
              },
              destinationSpot: {
                id: '906',
                name: 'Aeroporto Campo de Marte',
                address: {
                  id: '910',
                  cityId: '1',
                  zipCode: '02022-011',
                  address: 'Av. Olavo Fontoura',
                  complement: null,
                  number: '950',
                  neighborhood: 'Santana',
                  latitude: '-23.50979400',
                  longitude: '-46.64033700',
                  createDatetime: '2017-05-05 13:51:14',
                  updateDatetime: '2019-09-12 15:03:39',
                  name: null,
                  line: 'Av. Olavo Fontoura, 950',
                  isDefault: '0',
                  cityName: null,
                  stateName: null,
                  countryName: null,
                },
                airport: {
                  oaci: 'SBMT',
                  acronym: 'CDM',
                },
                helipad: {
                  oaci: 'SBMT',
                },
              },
              aircraft: {
                id: '27',
                highlightImageUrl:
                  'https://dlwwkvaei5hfp.cloudfront.net/aircrafts/1584818977126823_image.jpg',
                quantityOfPassengers: '3',
                cruiseSpeed: '185',
                model: {
                  id: '68',
                  manufacturer: 'Robinson',
                  model: 'R44 II',
                  highlightImageUrl:
                    'https://dlwwkvaei5hfp.cloudfront.net/aircrafts/models/r44_02_highlight.jpg',
                },
              },
              departureDatetime: '2020-09-15T21:17:00-03:00',
              estimatedArrivalDatetime: '2020-09-15T21:46:00-03:00',
              estimatedTime: '29',
              emptyLeg: '1',
              price: '1390.00',
            },
          ],
        },
      },
    ],
    totalPrice: 3750,
    expiryDatetime: '2020-09-16 10:29:17',
  },
  result: {
    status: 0,
    message: 'OK',
  },
};

export { PROPOSAL };
