// TODO: REFACTORING
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import FinishProposalUserData from '@components/FinishProposalUserData';
import FinishProposalUserAddress from '@components/FinishProposalUserAddress';
import FinishProposalPaymentType from '@components/FinishProposalPaymentType';
import FinishProposalLogin from '@components/FinishProposalLogin';
import SpinningWheel from '@components/SpinningWheel';
import FinishProposalCreated from '@components/FinishProposalCreated';

import { translate as t } from '@utils/translate';
import { isLoggedIn } from '@services/auth';
import { handleLogin } from '@services/auth';
import acceptProposal from '@services/acceptProposal';

import * as Styled from './FinishProposal.styles';

const FinishProposal = ({ isOpened, onClose }) => {
  // TODO TRANSLATIONS
  const TEXTS = {
    BACK: t('back'),
    NEXT: t('next'),
    LOGIN: t('login'),
  };
  const { selectedProposal } = useSelector(state => state.proposals);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [finishStep, setFinishStep] = useState(0);
  const [buttonTheme, setButtonTheme] = useState('disabled');
  const [proposal, setProposal] = useState({
    paymentInstructions: null,
    cartToken: null,
    userData: {
      id: null,
      name: '',
      email: '',
      document: '',
      documentRequired: false,
    },
    userAddress: {
      address: '',
      cityName: '',
      complement: '',
      countryName: '',
      name: '',
      number: '',
      stateName: '',
      zipCode: '',
    },
    paymentType: null,
    loginData: null,
  });

  /**
   * Load initial object
   */
  useEffect(() => {
    if (selectedProposal) {
      if (selectedProposal.owner) {
        setProposal(oldState => ({
          ...oldState,
          userData: {
            ...oldState.userData,
            id: selectedProposal.owner.id,
            name: selectedProposal.owner.name,
            email: selectedProposal.owner.emailAddress,
          },
        }));
      }

      if (selectedProposal.requestDocumentForInvoice) {
        setProposal(oldState => ({
          ...oldState,
          userData: {
            ...oldState.userData,
            documentRequired: Boolean(
              selectedProposal.requestDocumentForInvoice
            ),
          },
        }));
      }
    }
  }, [selectedProposal]);

  /**
   * Reset steps (componentDidMount/componentWillUnmount)
   */
  useEffect(() => {
    setFinishStep(0);
    setIsLoading(false);
  }, [isOpened]);

  useEffect(() => {
    setIsLoading(false);
    setFinishStep(oldState => (oldState += 1));
  }, [proposal.paymentInstructions]);

  /**
   * Validate form data based on current step
   */
  useEffect(() => {
    if (finishStep === 0) {
      verifyUserData(proposal.userData);
    } else if (finishStep === 1) {
      verifyUserAddress(proposal.userAddress);
    } else if (finishStep === 2) {
      verifyPaymentType(proposal.paymentType);
    }
  }, [proposal, finishStep]);

  /**
   * Validates user data
   *
   * @param {object} data User data object
   */
  function verifyUserData(data) {
    const { name, email, document, documentRequired } = data;

    if (name && name.trim() !== '' && email && email.trim() !== '') {
      setButtonTheme('active');
    } else {
      setButtonTheme('disabled');
    }

    if (documentRequired) {
      setButtonTheme('disabled');

      if (document && document.trim() !== '') {
        setButtonTheme('active');
      }
    }
  }

  /**
   * Validates user data address
   *
   * @param {object} data User address object
   */
  function verifyUserAddress(data) {
    setButtonTheme('disabled');

    if (typeof data === 'number') {
      setButtonTheme('active');
    } else if (typeof data === 'object') {
      const {
        address,
        cityName,
        countryName,
        name,
        number,
        stateName,
        zipCode,
      } = data;

      if (
        address &&
        address.trim() !== '' &&
        cityName &&
        cityName.trim() !== '' &&
        countryName &&
        countryName.trim() !== '' &&
        name &&
        name.trim() !== '' &&
        number &&
        number.trim() !== '' &&
        stateName &&
        stateName.trim() !== '' &&
        zipCode &&
        zipCode.trim() !== ''
      ) {
        setButtonTheme('active');
      }
    }
  }

  /**
   * Validate if payment type was chosen
   *
   * @param {number} data The payment type ID
   */
  function verifyPaymentType(data) {
    setButtonTheme('disabled');

    if (data) {
      setButtonTheme('active');
    }
  }

  /**
   * Render components based on steps
   */
  function renderStep() {
    switch (finishStep) {
      case 0:
        return (
          <FinishProposalUserData
            onChange={handleCallbacks}
            initialData={proposal.userData}
          />
        );
      case 1:
        return (
          <FinishProposalUserAddress
            onAdd={handleCallbacks}
            initialData={proposal.userAddress}
          />
        );
      case 2:
        return (
          <FinishProposalPaymentType
            onChoose={handleCallbacks}
            initialData={proposal.paymentType}
            methodsAllowed={selectedProposal.paymentMethodsAllowed}
          />
        );
      case 3:
        return (
          <FinishProposalCreated
            onFinish={() => handleCallbacks({ action: 'finish' })}
            onCheckout={() => handleCallbacks({ action: 'checkout' })}
            paymentInstructions={proposal.paymentInstructions}
          />
        );
      case 4:
        return <FinishProposalLogin onLogin={handleCallbacks} />;
      default:
        return <FinishProposalUserData />;
    }
  }

  /**
   * Navigate to preview step
   */
  function prevStep() {
    if (finishStep > 0) {
      setFinishStep(oldState => (oldState -= 1));
    }
  }

  /**
   * Navigate to next step
   */
  async function nextStep() {
    setErrorMessage(null);

    if (finishStep === 2) {
      setIsLoading(true);
      const response = await acceptProposal(selectedProposal, proposal);

      try {
        const {
          paymentTypeKey,
          wireTransferSetup,
        } = response.quotationProposal.charterContract.paymentMethodSelected;

        setProposal(oldState => ({
          ...oldState,
          cartToken: response.cart.token,
          paymentInstructions: {
            paymentTypeKey,
            wireTransferSetup,
          },
        }));
      } catch (error) {
        alert('Ocorreu um erro: ' + error.message);
      }

      setProposal(oldState => ({
        ...oldState,
        cartToken: response.cart.token,
      }));
    } else if (finishStep === 4) {
      doLogin();
    } else {
      setFinishStep(oldState => (oldState += 1));
    }
  }

  /**
   * Handle callbacks
   */
  async function handleCallbacks(data) {
    setErrorMessage(null);

    switch (finishStep) {
      case 0:
        setProposal(oldState => ({
          ...oldState,
          userData: data,
        }));
        break;
      case 1:
        setProposal(oldState => ({
          ...oldState,
          userAddress: data,
        }));
        break;
      case 2:
        setProposal(oldState => ({
          ...oldState,
          paymentType: data.id,
          paymentCurrency: data.currency,
        }));
      case 3: {
        handleFinalStep(data.action);
      }
      case 4:
        setProposal(oldState => ({
          ...oldState,
          loginData: data,
        }));
        break;
    }
  }

  /**
   * Do login and go to checkout page
   */
  async function doLogin() {
    setIsLoading(true);

    const {
      result: { status, message },
    } = await handleLogin(null, null, null, proposal.loginData);

    if (status === 0 && message.toLowerCase() === 'ok') {
      goToCheckout();
    } else {
      setErrorMessage('Credenciais inválidas');
      setIsLoading(false);
    }
  }

  /**
   * Handle action when user decides to send proposal by email
   * or go to checkout to start the payment process
   *
   * @param {string} action The action chosen by user
   */
  function handleFinalStep(action) {
    if (action === 'checkout') {
      if (!isLoggedIn()) {
        setFinishStep(oldState => (oldState += 1));
      } else {
        goToCheckout();
      }
    } else if (action === 'finish') {
      window.location.href = '/';
    }
  }

  /**
   * Go to checkout page
   */
  function goToCheckout() {
    window.location.href = `/checkout/${proposal.cartToken}`;
  }

  return (
    <>
      {isOpened ? (
        <Styled.FinishProposal>
          <Styled.ModalPanel>
            <Styled.CloseButton onClick={onClose} />
            {renderStep()}
            <Styled.NavButtons>
              {!isLoading ? (
                <>
                  {finishStep < 3 ? (
                    <>
                      <Styled.ButtonPrev theme="active" onClick={prevStep}>
                        {TEXTS.BACK}
                      </Styled.ButtonPrev>
                      <Styled.ButtonNext theme={buttonTheme} onClick={nextStep}>
                        {TEXTS.NEXT}
                      </Styled.ButtonNext>
                    </>
                  ) : (
                    <>
                      {finishStep === 4 ? (
                        <Styled.ButtonNext
                          theme={buttonTheme}
                          onClick={nextStep}
                        >
                          {TEXTS.LOGIN}
                        </Styled.ButtonNext>
                      ) : null}
                    </>
                  )}
                </>
              ) : (
                <SpinningWheel />
              )}
              {errorMessage ? (
                <Styled.ErrorMessage>{errorMessage}</Styled.ErrorMessage>
              ) : null}
            </Styled.NavButtons>
          </Styled.ModalPanel>
        </Styled.FinishProposal>
      ) : null}
    </>
  );
};

export default FinishProposal;
