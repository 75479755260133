// TODO: TRANSLATION PATTERN
// TODO: REFACTORING
import React from 'react';
import { translate as t } from '@utils/translate';
import * as Styled from './BankTransferContent.styles';

const BankTransferContent = ({
  onFinish,
  instructions,
  showActionButton = true,
  showTitle = true,
}) => {
  const {
    benefiaciaryAccount: beneficiaryAccount,
    intermediaryInstitutions,
  } = instructions;
  const TEXTS = {
    CREATE_TITLE: t('createdSuccessTitle'),
    WIRE_TRANSFER: t('wireTransferInstruction'),
    INTERMEDIARY_INSTITUTIONS: t('intermediaryInstitutionText'),
    AGENCY_TEXT: t('agencyText'),
    BANK_ACCOUNT: t('bankAccountText'),
    SWIFT_TEXT: t('swiftText'),
    BACK_HOME: t('backToHomepage'),
  };

  return (
    <>
      {showTitle && <Styled.Title>{TEXTS.CREATE_TITLE}</Styled.Title>}
      <Styled.Description>{TEXTS.WIRE_TRANSFER}</Styled.Description>

      <Styled.TransferInfoBlock>
        <span>{beneficiaryAccount.name}</span>
        <span>
          {beneficiaryAccount.showIban == 1 && (
            <>
              <strong>IBAN:</strong> {beneficiaryAccount.iban}
            </>
          )}
        </span>
        <span>
          {beneficiaryAccount.showIban == 0 && (
            <>
              <strong>CNPJ:</strong> {beneficiaryAccount.registrationNumber}
            </>
          )}
        </span>
      </Styled.TransferInfoBlock>

      <Styled.TransferInfoBlock>
        {beneficiaryAccount.bankAccounts.map((bankAccount, index) => (
          <p key={index}>
            {index > 0 && <br />}
            {beneficiaryAccount.showAgency == 1 && (
              <span>
                <strong>{TEXTS.INTERMEDIARY_INSTITUTIONS}:</strong>{' '}
                {bankAccount.agency}
              </span>
            )}
            {beneficiaryAccount.showAccount == 1 && (
              <span>
                <strong>{TEXTS.AGENCY_TEXT}:</strong>{' '}
                {bankAccount.accountNumber}
              </span>
            )}

            <span>
              <strong>{TEXTS.BANK_ACCOUNT}:</strong> {bankAccount.bank.number} -{' '}
              {bankAccount.bank.name}
            </span>
            {beneficiaryAccount.showSwift == 1 && (
              <span>
                <strong>{TEXTS.SWIFT_TEXT}</strong> {bankAccount.swift}
              </span>
            )}
          </p>
        ))}
      </Styled.TransferInfoBlock>

      {intermediaryInstitutions && (
        <Styled.TransferInfoBlock>
          {intermediaryInstitutions.bankAccounts.map(institution => {
            return (
              <p>
                <span>
                  <strong>{TEXTS.INTERMEDIARY_INSTITUTIONS}:</strong>{' '}
                  {institution.bank.name}
                </span>
                <span>
                  <strong>{TEXTS.SWIFT_TEXT}:</strong> {institution.swift}
                </span>
              </p>
            );
          })}
        </Styled.TransferInfoBlock>
      )}

      {showActionButton && (
        <Styled.ActionWrapper>
          <Styled.Button onClick={onFinish}>{TEXTS.BACK_HOME}</Styled.Button>
        </Styled.ActionWrapper>
      )}
    </>
  );
};

export default BankTransferContent;
