import styled, { keyframes } from 'styled-components';
import { ifProp } from 'styled-tools';

const animationIn = keyframes`
  0% { 
    left: 50px;
    opacity: 0; 
  }
  
  100% { 
    left: 0; 
    opacity: 1; 
  }
`;

const UserData = styled.div`
  animation: ${animationIn} 0.5s forwards;
  align-items: center;
  display: flex;
  font-size: 10px;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;
`;

const Form = styled.form`
  min-width: 50em;

  @media (max-width: 780px) {
    min-width: unset;
  }
`;

const Title = styled.h1`
  font-size: 2.4em;
  font-family: 'Montserrat';
  font-weight: 400;
  margin-bottom: 16px;
`;

const Subtitle = styled.h2`
  color: #a5a9b4;
  font-size: 1.6em;
  font-weight: 300;
  margin-top: 5px;
  margin-bottom: 2em;
`;

export { UserData, Form, Title, Subtitle };
