import styled, { keyframes } from 'styled-components';

const enterBottomAnimation = keyframes`
  0% { 
    bottom: -10%;
    opacity: 0;
  },
  100% { 
    bottom: 0%;
    opacity: 1;
  }
`;

export const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #ffffff;
  width: 260px;
  padding: 10px;
  padding-bottom: 20px;
  border-radius: 10px;
  color: black;
  -webkit-box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.3);
  bottom: 0;

  animation: ${enterBottomAnimation} 0.3s 0s;

  .title {
    font-weight: 700;
    color: #009688;
    width: 100%;
    margin-bottom: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #009688;
  }

  .info {
    font-weight: 700;
    color: #009688;
    width: 100%;
    margin-bottom: 2px;
  }

  .value {
    font-weight: 500;
    color: #009688;
    width: 100%;
    margin-bottom: 5px;
  }
`;
