// TODO: REFACTORING
import React, { useState } from 'react';
import Style from './style.module.css';
import { translate as t } from '../../utils/translate';

const ComfortItems = ({ items }) => {
  return items.map(({ icon, title, description }, index) => {
    return (
      <div className={Style.item} key={index}>
        <img className={Style.itemIcon} src={icon} />
        <div className={Style.itemContent}>
          <div className={Style.itemTitle}>{title}</div>
          <div className={Style.itemDescription}>{description}</div>
        </div>
      </div>
    );
  });
};

const AircraftComfort = props => {
  const { title, items, flightPage } = props;
  const [showAll, setShowAll] = useState(false);
  const TEXTS = {
    SEE_MORE: t('showMore'),
  };
  return (
    <section
      className={[
        Style.sectionComfort,
        flightPage ? [Style.short, Style.flightPage].join(' ') : '',
      ].join(' ')}
    >
      <h2 className={Style.title}>{title}</h2>
      <div
        className={[
          Style.itemsContainer,
          flightPage && !showAll ? Style.hideSomeItems : '',
        ].join(' ')}
      >
        <ComfortItems items={items} />
        {flightPage && !showAll && (
          <span onClick={() => setShowAll(true)} className={Style.seeMore}>
            {TEXTS.SEE_MORE}
          </span>
        )}
      </div>
    </section>
  );
};

export default AircraftComfort;
