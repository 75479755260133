// TODO: REFACTORING
import React from 'react';
import * as Styled from './Agreement.style';

import Checkbox from '@components/Checkbox';
import Loader from '@components/Loader';

import { translate as t } from '@utils/translate';

const Agreement = ({
  contentUrl,
  show,
  accepted,
  handleToggleAccept,
  isSelectable,
}) => {
  const TEXTS = {
    TITLE: t('generalCharterInformation'),
    CHECKBOX_LABEL: t('acceptTerms'),
  };

  return (
    <>
      {!contentUrl ? (
        <>
          <Loader
            customStyles={{
              height: '50px',
              backgroundColor: 'transparent',
              position: 'relative',
            }}
          ></Loader>
        </>
      ) : (
        show && (
          <Styled.Agreement>
            <Styled.SectionTitle>{TEXTS.TITLE}</Styled.SectionTitle>

            <Styled.TextArea src={contentUrl}></Styled.TextArea>
            {isSelectable && (
              <Checkbox
                selected={accepted}
                text={TEXTS.CHECKBOX_LABEL}
                fullWidth={true}
                labelColor={'#747474'}
                onClick={handleToggleAccept}
              />
            )}
          </Styled.Agreement>
        )
      )}
    </>
  );
};

export default Agreement;
