// TODO: REFACTORING
import React, { useState, useEffect } from 'react';
import * as Styled from './ActionBar.style';
// import { navigate } from '@reach/router';
import { navigate } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import Button from '../Button';
import Arrow from '../Arrow';
import Price from '../Price';
import Modal from '@components/Modal';
import BankTransferContent from '@components/BankTransferContent';
import FlightLabelDetails from './FlightLabels/FlightLabelsDetails';
import HiddenFlightLabels from './FlightLabels/HiddenFlightLabels';
import AnimatedTooltip from '@components/AnimatedComponents/AnimatedTooltip/AnimatedTooltip';

import { translate as t } from '@utils/translate';
import { solveIataOaci } from '@utils/spots';
import { isLoggedIn } from '@services/auth';
import createCheckout from '@services/createCheckout';

const PendingProposalActions = ({
  segments,
  value,
  currency,
  onClick,
  onClickFlightLabel,
}) => {
  const TEXTS = {
    PRICE_LABEL: t('totalPrice'),
    BUTTON_CHOOSE_OPTION_LABEL: t('chooseThisOption'),
    FLIGHT: t('flight'),
  };
  const [maxVisibleLabels, setMaxVisibleLabels] = useState(5);
  const breakpoint = useBreakpoint();

  useEffect(() => {
    if (breakpoint.sm) setMaxVisibleLabels(1);
    else if (breakpoint.md) setMaxVisibleLabels(2);
    else if (breakpoint.l) setMaxVisibleLabels(4);
    else setMaxVisibleLabels(5);
  }, [breakpoint]);

  const visibleItems = segments.slice(0, maxVisibleLabels);
  const hiddenItems = segments.slice(maxVisibleLabels);

  return (
    <Styled.BottomFixedInfo>
      <div className={'origin-destination'}>
        {visibleItems.map((flightLabel, index) => {
          const origin = solveIataOaci(flightLabel.originSpot, 'only-once');
          const destination = solveIataOaci(
            flightLabel.destinationSpot,
            'only-once'
          );
          const labelData = {
            aircraft: flightLabel.aircraft.name,
            origin: flightLabel.originSpot.name,
            destination: flightLabel.destinationSpot.name,
            departure: flightLabel.departureDatetime,
            flightNumber: index,
          };

          return (
            <AnimatedTooltip
              label={<FlightLabelDetails item={labelData} />}
              aria-label={origin}
              className={'tooltip'}
              key={index}
              tooltipStyles={{
                background: '#00988900',
                boxShadow: 'none',
                borderRadius: 10,
                padding: 5,
                color: 'white',
                marginLeft: -80,
              }}
            >
              <div
                key={index}
                className={'flight-label'}
                onClick={() => onClickFlightLabel(index)}
              >
                <strong>{`${TEXTS.FLIGHT} ${index + 1}`}</strong>
                <div>
                  <span>{origin}</span>
                  <Arrow direction={'right'}></Arrow>
                  <span>{destination}</span>
                </div>
              </div>
            </AnimatedTooltip>
          );
        })}
        {hiddenItems.length > 0 ? (
          <AnimatedTooltip
            label={
              <HiddenFlightLabels
                items={hiddenItems.slice(0, 8)}
                maxVisibleLabels={maxVisibleLabels}
              />
            }
            tooltipStyles={{
              background: '#00988900',
              boxShadow: 'none',
              padding: 0,
              marginBottom: 20,
              marginLeft: -110,
              color: 'white',
            }}
          >
            <Styled.Plus onClick={() => onClickFlightLabel(maxVisibleLabels)}>
              +{hiddenItems.length}
            </Styled.Plus>
          </AnimatedTooltip>
        ) : null}
      </div>

      <div className={'price-action'}>
        <div className={'price'}>
          <div>
            <span>{TEXTS.PRICE_LABEL}</span>
            <Price values={value} currencies={currency} />
          </div>
        </div>
        <Button
          theme={'active'}
          label={TEXTS.BUTTON_CHOOSE_OPTION_LABEL}
          onClick={onClick}
        />
      </div>
    </Styled.BottomFixedInfo>
  );
};

const AcceptedProposalActions = ({
  proposalId,
  handleLogin,
  paymentData,
  currency,
}) => {
  const TEXTS = {
    GOTO_CHECKOUT_LABEL: t('goToCheckoutText'),
    ACCOUNT_LABEL: t('viewBankDetails'),
  };
  const [isCreditCardMethod, setIsCreditCardMethod] = useState(false);
  const [buttonState, setButtonState] = useState('initial');
  const [hidePanel, setHidePanel] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);

  useEffect(() => {
    setIsCreditCardMethod(paymentData.isCreditCard);
  }, [paymentData]);

  const createCart = () => {
    if (isLoggedIn()) {
      createCheckout(
        4,
        {
          quotationProposal: {
            id: proposalId,
          },
        },
        currency.current
      )
        .then(cartId => {
          setButtonState('initial');
          navigate(`/checkout/${cartId}`);
        })
        .catch(err => {
          console.error(err);
          setButtonState('initial');
        });
    } else {
      setButtonState('initial');
      setHidePanel(false);
    }
  };

  const showBankingInfo = () => {
    setButtonState('disabled');
    setHidePanel(false);
    setShowInfoModal(true);
  };

  const handleLoginCallback = () => {
    if (!handleLogin) return null;
    setHidePanel(true);
    handleLogin(true, createCart);
  };

  const handleClick = () => {
    setButtonState('loading');
    if (!isLoggedIn() && isCreditCardMethod) handleLoginCallback();
    else {
      if (isCreditCardMethod) createCart();
      else showBankingInfo();
    }
  };

  const closeInfoModal = () => {
    setButtonState('initial');
    setShowInfoModal(false);
  };

  return (
    <>
      {showInfoModal && (
        <Modal opened={showInfoModal} closeModal={closeInfoModal}>
          <Styled.ModalInfoWrapper>
            <Styled.BankingInfoWrapper>
              <BankTransferContent
                instructions={paymentData.wireTrasferSetup}
                showActionButton={false}
                showTitle={false}
              />
            </Styled.BankingInfoWrapper>
          </Styled.ModalInfoWrapper>
        </Modal>
      )}
      <Styled.BottomFixedInfo hidden={hidePanel}>
        <div className={'bottom-fixed-buttons-wrapper'}>
          {isCreditCardMethod ? (
            <Button
              theme={'active'}
              label={TEXTS.GOTO_CHECKOUT_LABEL}
              status={buttonState}
              onClick={() => handleClick(true)}
              resetAfterFinish={false}
            />
          ) : (
            <Button
              theme={'active'}
              label={TEXTS.ACCOUNT_LABEL}
              status={buttonState}
              onClick={() => handleClick(false)}
              resetAfterFinish={false}
            />
          )}
        </div>
      </Styled.BottomFixedInfo>
    </>
  );
};

const ActionBar = ({
  proposalStatus = 2,
  segments,
  value,
  currency,
  onClick,
  onClickFlightLabel,
  proposalId,
  handleLogin,
  paymentData,
}) => {
  if (proposalStatus === 2) {
    return (
      <PendingProposalActions
        segments={segments}
        value={value}
        currency={currency}
        onClick={onClick}
        onClickFlightLabel={onClickFlightLabel}
      />
    );
  } else if (proposalStatus === 3) {
    return (
      <AcceptedProposalActions
        proposalId={proposalId}
        handleLogin={handleLogin}
        paymentData={paymentData}
        currency={currency}
      />
    );
  }
};

export default ActionBar;
