function getCurrencyList(language) {
  return {
    en_US: [
      { code: 'BRL', description: '(BRL) Brazilian Real' },
      { code: 'USD', description: '(USD) US Dollar' },
      { code: 'EUR', description: '(EUR) Euro' },
      { code: 'GBP', description: '(GBP) British Pound' },
      { code: 'CHF', description: '(CHF) Swiss franc' },
      { code: 'CAD', description: '(CAD) Canadian dollar' },
    ],
    pt_BR: [
      { code: 'BRL', description: '(BRL) Real' },
      { code: 'USD', description: '(USD) Dólar Americano' },
      { code: 'EUR', description: '(EUR) Euro' },
      { code: 'GBP', description: '(GBP) Libra' },
      { code: 'CHF', description: '(CHF) Franco Suíço' },
      { code: 'CAD', description: '(CAD) Dólar Canadense' },
    ],
    es_ES: [
      { code: 'BRL', description: '(BRL) Real Brasileño' },
      { code: 'USD', description: '(USD) Dólar Estadounidense' },
      { code: 'EUR', description: '(EUR) Euro' },
      { code: 'GBP', description: '(GBP) Libra' },
      { code: 'CHF', description: '(CHF) Franco suizo' },
      { code: 'CAD', description: '(CAD) Dólar Canadiense' },
    ],
    // it_IT: [
    //   { code: 'BRL', description: '(BRL) Real Brasileño' },
    //   { code: 'USD', description: '(USD) Dólar Estadounidense' },
    //   { code: 'EUR', description: '(EUR) Euro' },
    //   { code: 'GBP', description: '(GBP) Libra' },
    //   { code: 'CHF', description: '(CHF) Franco suizo' },
    //   { code: 'CAD', description: '(CAD) Dólar Canadiense' },
    // ],
  }[language];
}

export default getCurrencyList;
