import styled, { keyframes, css } from 'styled-components';
import { COLORS } from '@utils/styles/colors';

const enterBottomAnimation = keyframes`
  0% { 
    bottom: -10%;
    opacity: 0;
  },
  100% { 
    bottom: 0%;
    opacity: 1;
  }
`;

export const BottomFixedInfo = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999999;

  /* display: flex; */
  ${props =>
    props.hidden
      ? css`
          display: none;
        `
      : css`
          display: flex;
        `}
  flex: 1;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 80px;

  background-color: white;
  box-shadow: 0px 2px 15px #00000069;

  padding-left: var(--container-size);
  padding-right: var(--container-size);

  animation: ${enterBottomAnimation} 1.3s 0s;

  > div {
    display: flex;
    align-items: center;
    /* width: 50%; */
  }

  .origin-destination {
    justify-content: flex-start;
    max-width: 795px;
    padding: 17px;
    border-left: 1px solid #00968825;
    border-right: 1px solid #00968825;
    // border-radius: 10px;

    img {
      max-width: 12px;
      margin: 0 5px;
    }

    > .flight-label {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin-right: 45px;
      width: 100px;
      opacity: 0.9;

      > div {
        display: flex;
      }

      &:hover {
        opacity: 1;
      }

      span {
        font-size: 18px;
        color: #009688;
        font-weight: bold;
      }
    }
  }

  .price-action {
    justify-content: space-between;
    flex: 1;

    .price {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: flex-end;
      margin-right: 30px;

      > div > div {
        color: ${COLORS.CELADON_GREEN};
      }

      > div > span {
        font-size: 14px;
        font-weight: bold;
      }
    }
  }

  .bottom-fixed-buttons-wrapper {
    display: flex;
    flex: 1;
    justify-content: flex-end;

    button {
      margin-left: 10px;
    }
  }

  @media (max-width: 720px) {
    height: auto;
    padding: 0;
    width: 100%;
    justify-content: space-between;
    box-shadow: 0px 2px 15px #00000069;

    .bottom-fixed-buttons-wrapper {
      flex-direction: column;
      padding-bottom: 10px;

      button {
        margin: 10px 0 0 0;
      }
    }
  }
`;

export const ModalInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const BankingInfoWrapper = styled.div`
  display: block;
  max-width: 600px;
  margin-bottom: 30px;
`;

export const Plus = styled.span`
  cursor: pointer;
  line-height: 100%;
  font-size: 18px;
  font-weight: 600;
  transition: color 1s ease-in;
  color: #fff;
  padding: 10px;
  border-radius: 25px;
  background-color: #009688;
`;
