// react/third-part modules
import React, { useEffect, useState, useMemo } from 'react';
import * as Styled from './Proposals.style';
import { navigate, useParams } from '@reach/router';
import { useStore, useSelector } from 'react-redux';
import { sprintf } from 'sprintf-js';

// component modules
import BackButton from '@components/BackButton';
import ProposalCard from '@components/ProposalCard';
import Loader from '@components/Loader';
import FinishProposal from '@components/FinishProposal';
import Snackbar from '@components/Snackbar';

// contexts/hooks/others
import { translate as t, useCurrentLang } from '@utils/translate';
import {
  fetchProposals,
  setProposalsData,
  fetchAircraftDetails,
  setAircraftsDetails,
} from '@store/reducers/proposals/operations';
import { setLanguage } from '@store/reducers/general/operations';

const Proposals = ({ handleLogin }) => {
  const store = useStore();
  const proposalData = useSelector(state => state.proposals.proposalData);
  const aircraftsDetails = useSelector(
    state => state.proposals.aircraftsDetails
  );
  const [isFetching, setIsFetching] = useState(true);
  const [proposalItems, setProposalItems] = useState([]);
  const [bestPriceProposalId, setBestPriceProposalId] = useState(null);
  const [finishProposal, setFinishProposal] = useState(false);
  const pParams = useParams();
  const [proposalStatus, setProposalStatus] = useState(null);
  const language = proposalData.language;

  const TEXTS = {
    PROPOSAL_DESCRIPTION: t('introText'),
    PROPOSAL_PENDING_DESCRIPTION: t('pendingProposal'),
    PROPOSAL_CANCELED_DESCRIPTION: t('unavailableProposal'),
    LOADING_INFO: t('loadingProposals'),
    BACKBUTTON_LABEL: t('back'),
    PROPOSAL_TITLE: t('proposal'),
  };

  useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    let qsLanguage = urlParams.get('lang');
    if (qsLanguage && language && qsLanguage !== language) {
      urlParams.set('lang', language);
      navigate(`${window.location.pathname}?${urlParams.toString()}`);
    }
  }, [language]);

  useEffect(() => {
    if ([1, 2, 3, 4].includes(proposalStatus)) {
      let availableProposals = [];
      let aircraftIds = [];
      if (proposalData && proposalData['items']) {
        let bestPrice = {
          value: 99999999,
          proposalId: null,
        };
        proposalData['items'].forEach(proposal => {
          if (proposal.localTotalAmountAdjusted < bestPrice.value) {
            bestPrice.value = proposal.localTotalAmountAdjusted;
            bestPrice.proposalId = proposal.id;
          }

          if (
            proposalData.statusId === 2 ||
            ([3, 4].includes(proposalData.statusId) && proposal.isSelected)
          ) {
            availableProposals.push(proposal);
          }

          // get id's to fetch aircraft data
          proposal.segments.forEach(segment => {
            const aircraftId = segment.aircraft.id;
            if (
              !aircraftsDetails[aircraftId] &&
              aircraftIds.indexOf(aircraftId) === -1
            )
              aircraftIds.push(aircraftId);
          });
        });

        if (aircraftIds.length) {
          try {
            store
              .dispatch(fetchAircraftDetails(aircraftIds))
              .then(res => {
                if (res.result.status === 0) {
                  if (res.aircrafts[0]['id']) {
                    res.aircrafts.forEach(aircraft => {
                      store.dispatch(setAircraftsDetails(aircraft));
                    });
                    setIsFetching(false);
                  }
                }
              })
              .catch(error => console.error(error));
          } catch (error) {
            console.error(error);
          }
        }

        setBestPriceProposalId(bestPrice.proposalId);
        setProposalItems([...availableProposals]);
      }
    } else if ([1, 5].includes(proposalStatus)) {
      setIsFetching(false);
    }
  }, [proposalData, proposalStatus]);

  // fetch proposals data
  useEffect(() => {
    setIsFetching(true);
    try {
      store
        .dispatch(fetchProposals(pParams.hash, language))
        .then(res => {
          if (res.result.status === 0) {
            store.dispatch(setProposalsData({ ...res.quotationProposal }));
          } else {
            console.error(res);
          }
          setIsFetching(false);
        })
        .catch(error => {
          if ([90, 91].includes(error['result']['status'])) {
            setProposalStatus(5);
          }
          setIsFetching(false);
          console.error(error);
        });
    } catch (error) {
      setIsFetching(false);
      console.error(error);
    }
  }, []);

  // finish proposal steps
  function handleAccept() {
    setFinishProposal(true);
  }

  function closeProposalModal() {
    setFinishProposal(false);
  }

  useEffect(() => {
    if (proposalData.statusId) setProposalStatus(proposalData.statusId);
  }, [proposalData]);

  return (
    <>
      <FinishProposal isOpened={finishProposal} onClose={closeProposalModal} />
      {isFetching ? (
        <Loader>
          <p>{TEXTS.LOADING_INFO}</p>
        </Loader>
      ) : (
        <>
          <Styled.ProposalContainer>
            <Styled.BackButtonWrapper>
              <BackButton text={TEXTS.BACKBUTTON_LABEL} />
            </Styled.BackButtonWrapper>

            {[2, 3, 4].includes(proposalData.statusId) ? (
              <>
                <Styled.ProposalsTitle>
                  {TEXTS.PROPOSAL_TITLE} nº{proposalData.id}
                </Styled.ProposalsTitle>

                <Styled.ProposalsDescription
                  dangerouslySetInnerHTML={{
                    __html: `${sprintf(
                      TEXTS.PROPOSAL_DESCRIPTION,
                      proposalData.owner.name || ''
                    )}`,
                  }}
                />

                {proposalData.observations && (
                  <Styled.ObservationsWrapper>
                    <Snackbar type="info" icon="info">
                      {proposalData.observations}
                    </Snackbar>
                  </Styled.ObservationsWrapper>
                )}

                <Styled.ProposalsListWrapper>
                  {proposalItems.map((proposal, index) => (
                    <ProposalCard
                      proposal={proposal}
                      aircraftsDetails={aircraftsDetails}
                      showOperatorCurrency={proposalData.showOperatorCurrency}
                      index={index}
                      key={index}
                      bestPrice={proposal.id === bestPriceProposalId}
                      status={proposalStatus}
                      onAccept={handleAccept}
                      handleLogin={handleLogin}
                    />
                  ))}
                </Styled.ProposalsListWrapper>
              </>
            ) : proposalStatus === 1 ? (
              <Styled.ProposalsDescription>
                {sprintf(
                  TEXTS.PROPOSAL_PENDING_DESCRIPTION,
                  proposalData.owner.name
                )}
              </Styled.ProposalsDescription>
            ) : (
              <Styled.ProposalsDescription>
                {TEXTS.PROPOSAL_CANCELED_DESCRIPTION}
              </Styled.ProposalsDescription>
            )}
          </Styled.ProposalContainer>
        </>
      )}
    </>
  );
};

export default Proposals;
