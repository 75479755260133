// TODO: REFACTORING
import React from 'react';
import Style from './style.module.css';

import { translate as t } from '../../utils/translate';

const CharacteristicItem = ({ type, value }) => {
  const TEXTS = {
    LUGGAGE: t('luggage'),
    LUGGAGE_VOLUME: t('luggageVolume'),
    MAX_WEIGHT: t('maxWeight'),
    LOAD_TIME: t('loadTime'),
    RANGE: t('range'),
    EMPTY_RANGE: t('emptyRange'),
    MAX_RANGE: t('maxRange'),
    VELOCITY: t('velocity'),
    MAX_VOLUME: t('maxVolume'),
    COMPARTMENTS: t('compartments'),
    TOTAL_SLOTS: t('totalSlots'),
    PASSENGERS_NUMBER: t('passengersNumber'),
    PILOTS_NUMBER: t('pilotsNumber'),
    TOTAL_BEDS: t('totalBeds'),
    MEDICAL_CREW: t('medicalCrew'),
  };

  const types = {
    luggageRackVolume: {
      icon:
        '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 34 34"><g transform="translate(-97 -110.561)"><path d="M27.767,8.045l-5.5,5.5h3.766a1.328,1.328,0,0,1,0,2.656h-4.98a3.324,3.324,0,0,1-3.32-3.32V7.9a1.328,1.328,0,1,1,2.656,0v3.766l5.5-5.5a1.328,1.328,0,0,1,1.879,1.878ZM15.008,27.227A1.328,1.328,0,0,0,16.336,25.9v-4.98a3.324,3.324,0,0,0-3.32-3.32H8.035a1.328,1.328,0,1,0,0,2.656H11.8l-5.5,5.5a1.328,1.328,0,0,0,1.879,1.878s4.127-5.068,5.5-5.5,0,3.766,0,3.766a1.328,1.328,0,0,0,1.328,1.328Zm17.664-3.852A1.328,1.328,0,0,0,34,22.047V5.313A5.318,5.318,0,0,0,28.688,0H5.313A5.318,5.318,0,0,0,0,5.313V28.688A5.318,5.318,0,0,0,5.313,34H28.688A5.318,5.318,0,0,0,34,28.688a1.328,1.328,0,0,0-2.656,0,2.659,2.659,0,0,1-2.656,2.656H5.313a2.659,2.659,0,0,1-2.656-2.656V5.313A2.659,2.659,0,0,1,5.313,2.656H28.688a2.659,2.659,0,0,1,2.656,2.656V22.047A1.328,1.328,0,0,0,32.672,23.375Zm0,0" transform="translate(97 110.561)"/></g></svg>',
      text: TEXTS.LUGGAGE_VOLUME,
    },
    numberOfLuggage: {
      icon:
        '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 34 34"><g transform="translate(-97 -110.561)"><path d="M27.767,8.045l-5.5,5.5h3.766a1.328,1.328,0,0,1,0,2.656h-4.98a3.324,3.324,0,0,1-3.32-3.32V7.9a1.328,1.328,0,1,1,2.656,0v3.766l5.5-5.5a1.328,1.328,0,0,1,1.879,1.878ZM15.008,27.227A1.328,1.328,0,0,0,16.336,25.9v-4.98a3.324,3.324,0,0,0-3.32-3.32H8.035a1.328,1.328,0,1,0,0,2.656H11.8l-5.5,5.5a1.328,1.328,0,0,0,1.879,1.878s4.127-5.068,5.5-5.5,0,3.766,0,3.766a1.328,1.328,0,0,0,1.328,1.328Zm17.664-3.852A1.328,1.328,0,0,0,34,22.047V5.313A5.318,5.318,0,0,0,28.688,0H5.313A5.318,5.318,0,0,0,0,5.313V28.688A5.318,5.318,0,0,0,5.313,34H28.688A5.318,5.318,0,0,0,34,28.688a1.328,1.328,0,0,0-2.656,0,2.659,2.659,0,0,1-2.656,2.656H5.313a2.659,2.659,0,0,1-2.656-2.656V5.313A2.659,2.659,0,0,1,5.313,2.656H28.688a2.659,2.659,0,0,1,2.656,2.656V22.047A1.328,1.328,0,0,0,32.672,23.375Zm0,0" transform="translate(97 110.561)"/></g></svg>',
      text: TEXTS.LUGGAGE,
    },
    maximumPayload: {
      icon:
        '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 34 34"><g transform="translate(-97 -189.966)"><g transform="translate(97 189.966)"><path d="M29.152,9.066h-7.8a5.42,5.42,0,0,0,1.317-3.537,5.675,5.675,0,0,0-11.346,0,5.42,5.42,0,0,0,1.317,3.537h-7.8L0,34H34ZM17,1.992a3.588,3.588,0,0,1,3.629,3.537,3.63,3.63,0,0,1-7.258,0A3.588,3.588,0,0,1,17,1.992ZM6.541,11.058H27.459l4.073,20.949H2.467Zm0,0" transform="translate(0 0)"/><path d="M151.275,264.59h-2.337l-3.267,3.263V264.59h-1.679v8.228h1.679v-2.812l.847-.826,2.559,3.638h2.168l-3.528-4.792Zm0,0" transform="translate(-134.201 -247.2)"/><path d="M247.174,268.115h2.219v.9a3.438,3.438,0,0,1-.724.38,3.023,3.023,0,0,1-1.139.225,2.807,2.807,0,0,1-1.26-.294,1.874,1.874,0,0,1-.86-.859,3.37,3.37,0,0,1-.31-1.534,3.631,3.631,0,0,1,.262-1.387,2.294,2.294,0,0,1,.415-.65,1.885,1.885,0,0,1,.684-.462,2.745,2.745,0,0,1,1.053-.182,2.321,2.321,0,0,1,.908.171,1.4,1.4,0,0,1,.583.421,2.471,2.471,0,0,1,.366.8l.094.32,1.546-.424-.083-.324a3.625,3.625,0,0,0-.635-1.362,2.8,2.8,0,0,0-1.177-.843,4.3,4.3,0,0,0-1.607-.29,4.51,4.51,0,0,0-2.153.5,3.41,3.41,0,0,0-1.472,1.57,5.028,5.028,0,0,0-.495,2.2,4.691,4.691,0,0,0,.5,2.169,3.5,3.5,0,0,0,1.517,1.523,4.642,4.642,0,0,0,2.178.515,4.861,4.861,0,0,0,1.727-.318,6,6,0,0,0,1.61-.935l.128-.1v-3.306l-3.878.006Zm0,0" transform="translate(-226.842 -245.459)"/></g></g></svg>',
      text: TEXTS.MAX_WEIGHT,
      formatValue: value => `${value} Kg`,
    },
    averageLoadingTime: {
      icon:
        '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 34 34"><g transform="translate(-97 -273.514)"><g transform="translate(97 273.514)"><path d="M32.067,22.227A15.544,15.544,0,0,1,17.547,32,15.267,15.267,0,0,1,2.061,17,15.267,15.267,0,0,1,17.547,2,15.629,15.629,0,0,1,28.334,6.232l-2.007-.171-.18,1.99,5.4.459.474-5.234L29.969,3.1l-.157,1.742A17.745,17.745,0,0,0,17.547,0,17.721,17.721,0,0,0,5.139,4.979a16.628,16.628,0,0,0,0,24.042,17.979,17.979,0,0,0,22.532,1.866A17.041,17.041,0,0,0,34,22.923Zm0,0" transform="translate(0.001 0)"/><path d="M243.018,163.164h-2.076v7.447h5.19v-2.076h-3.113Zm0,0" transform="translate(-224.448 -152.443)"/><path d="M109.354,98.887c-5.981,0-10.846,4.564-10.846,10.173s4.866,10.173,10.846,10.173S120.2,114.67,120.2,109.06,115.335,98.887,109.354,98.887Zm0,18.4a8.243,8.243,0,1,1,8.77-8.226A8.52,8.52,0,0,1,109.354,117.286Zm0,0" transform="translate(-91.839 -92.06)"/></g></g></svg>',
      text: TEXTS.LOAD_TIME,
    },
    maximumRange: {
      icon:
        '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 34 34"><g transform="translate(-97 -346.584)"><g transform="translate(97 346.584)"><g transform="translate(0 0)"><path d="M33,16a1,1,0,0,0-1,1A15.008,15.008,0,1,1,26.884,5.708L23.757,8.835A10.6,10.6,0,1,0,27.6,17a1,1,0,0,0-1.992,0,8.6,8.6,0,1,1-3.266-6.749l-3.161,3.162a4.194,4.194,0,1,0,1.409,1.409l8.433-8.433a1,1,0,0,0,0-1.409A17,17,0,0,0,4.979,29.022,17,17,0,0,0,34,17,1,1,0,0,0,33,16Zm-16,3.2a2.2,2.2,0,1,1,1.559-3.764h0A2.2,2.2,0,0,1,17,19.206Z" transform="translate(0 -0.001)"/></g></g></g></svg>',
      text: TEXTS.RANGE,
      formatValue: value => `${value} Km`,
    },
    maximumRangeEmpty: {
      icon:
        '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 34 34"><g transform="translate(-97 -346.584)"><g transform="translate(97 346.584)"><g transform="translate(0 0)"><path d="M33,16a1,1,0,0,0-1,1A15.008,15.008,0,1,1,26.884,5.708L23.757,8.835A10.6,10.6,0,1,0,27.6,17a1,1,0,0,0-1.992,0,8.6,8.6,0,1,1-3.266-6.749l-3.161,3.162a4.194,4.194,0,1,0,1.409,1.409l8.433-8.433a1,1,0,0,0,0-1.409A17,17,0,0,0,4.979,29.022,17,17,0,0,0,34,17,1,1,0,0,0,33,16Zm-16,3.2a2.2,2.2,0,1,1,1.559-3.764h0A2.2,2.2,0,0,1,17,19.206Z" transform="translate(0 -0.001)"/></g></g></g></svg>',
      text: TEXTS.EMPTY_RANGE,
    },
    maximumRangeFullCapacity: {
      icon:
        '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 34 34"><g transform="translate(-97 -346.584)"><g transform="translate(97 346.584)"><g transform="translate(0 0)"><path d="M33,16a1,1,0,0,0-1,1A15.008,15.008,0,1,1,26.884,5.708L23.757,8.835A10.6,10.6,0,1,0,27.6,17a1,1,0,0,0-1.992,0,8.6,8.6,0,1,1-3.266-6.749l-3.161,3.162a4.194,4.194,0,1,0,1.409,1.409l8.433-8.433a1,1,0,0,0,0-1.409A17,17,0,0,0,4.979,29.022,17,17,0,0,0,34,17,1,1,0,0,0,33,16Zm-16,3.2a2.2,2.2,0,1,1,1.559-3.764h0A2.2,2.2,0,0,1,17,19.206Z" transform="translate(0 -0.001)"/></g></g></g></svg>',
      text: TEXTS.MAX_RANGE,
    },
    cruiseSpeed: {
      icon:
        '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 34 34"><defs><style>.a{fill:#fff;}</style></defs><g transform="translate(-97 -418)"><g transform="translate(0 -433)"><rect class="a" width="16" height="16" transform="translate(97 851)"/><g transform="translate(97 787.145)"><g transform="translate(0 68.855)"><path d="M19.372,73.368l1.839-4.142A24.023,24.023,0,0,0,0,75.879L3.091,78.97A19.638,19.638,0,0,1,19.372,73.368Z" transform="translate(0 -68.855)"/><path d="M96.639,189.613a15.273,15.273,0,0,0-11.3,4.451l3.091,3.091a10.819,10.819,0,0,1,6.228-3.083Z" transform="translate(-79.152 -180.858)"/><path d="M200.821,80.478a.761.761,0,0,0-.634.355l-.1.224L192.074,99.07a3.023,3.023,0,0,0-.394,1.491,3.1,3.1,0,0,0,6.128.649l.008-.054,3.779-19.905A.775.775,0,0,0,200.821,80.478Z" transform="translate(-177.794 -79.636)"/><path d="M315.307,215.338a10.86,10.86,0,0,1,1.885,1.5l3.091-3.091a15.3,15.3,0,0,0-4.119-2.921Z" transform="translate(-292.465 -200.54)"/><path d="M339.6,89.652,338.774,94a19.6,19.6,0,0,1,6.367,4.258l3.091-3.091A23.927,23.927,0,0,0,339.6,89.652Z" transform="translate(-314.232 -88.145)"/></g></g></g></g></svg>',
      text: TEXTS.VELOCITY,
      formatValue: value =>
        `${value} km/h | ${Math.round(((value / 1.852) * 100) / 100).toFixed(
          2
        )} kts`,
    },
    maximumVolume: {
      icon:
        '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 34 34"><g transform="translate(-97 -110.561)"><path d="M27.767,8.045l-5.5,5.5h3.766a1.328,1.328,0,0,1,0,2.656h-4.98a3.324,3.324,0,0,1-3.32-3.32V7.9a1.328,1.328,0,1,1,2.656,0v3.766l5.5-5.5a1.328,1.328,0,0,1,1.879,1.878ZM15.008,27.227A1.328,1.328,0,0,0,16.336,25.9v-4.98a3.324,3.324,0,0,0-3.32-3.32H8.035a1.328,1.328,0,1,0,0,2.656H11.8l-5.5,5.5a1.328,1.328,0,0,0,1.879,1.878s4.127-5.068,5.5-5.5,0,3.766,0,3.766a1.328,1.328,0,0,0,1.328,1.328Zm17.664-3.852A1.328,1.328,0,0,0,34,22.047V5.313A5.318,5.318,0,0,0,28.688,0H5.313A5.318,5.318,0,0,0,0,5.313V28.688A5.318,5.318,0,0,0,5.313,34H28.688A5.318,5.318,0,0,0,34,28.688a1.328,1.328,0,0,0-2.656,0,2.659,2.659,0,0,1-2.656,2.656H5.313a2.659,2.659,0,0,1-2.656-2.656V5.313A2.659,2.659,0,0,1,5.313,2.656H28.688a2.659,2.659,0,0,1,2.656,2.656V22.047A1.328,1.328,0,0,0,32.672,23.375Zm0,0" transform="translate(97 110.561)"/></g></svg>',
      text: TEXTS.MAX_VOLUME,
    },
    numberOfCompartments: {
      icon:
        '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 34 34"><defs><style>.a{fill:#fff;}</style></defs><g transform="translate(-771 -114)"><g transform="translate(0 -457)"><rect class="a" width="16" height="16" transform="translate(771 571)"/><path d="M2.1,17h0L0,17V0H34V17l-2.382,0V15.607H2.1V17ZM18.287,5.246H29.028V12.93h2.59V2.686H2.1V12.93H4.7V5.246H15.433V12.93h2.854V5.246Zm2.677,2.68v5h5.367v-5Zm-13.569,0v5h5.357v-5Z" transform="translate(771 579.998)"/></g></g></svg>',
      text: TEXTS.COMPARTMENTS,
    },
    numberOfSlots: {
      icon:
        '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 34 34"><g transform="translate(-771 -195.002)"><g transform="translate(0 -435)"><path d="M2.1,17h0L0,17V0H34V17l-2.382,0V15.607H2.1V17ZM18.287,5.246H29.028V12.93h2.59V2.686H2.1V12.93H4.7V5.246H15.433V12.93h2.854V5.246Zm2.677,2.68v5h5.367v-5Zm-13.569,0v5h5.357v-5Z" transform="translate(771 647)"/><path d="M2.1,17h0L0,17V0H34V17l-2.382,0V15.607H2.1V17ZM18.287,5.246H29.028V12.93h2.59V2.686H2.1V12.93H4.7V5.246H15.433V12.93h2.854V5.246Zm2.677,2.68v5h5.367v-5Zm-13.569,0v5h5.357v-5Z" transform="translate(771 630)"/></g></g></svg>',
      text: TEXTS.TOTAL_SLOTS,
    },
    numberOfPassengers: {
      icon:
        '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 34 34"><defs><style>.a{fill:#fff;}</style></defs><g transform="translate(-771 -273)"><g transform="translate(0 -549)"><rect class="a" width="16" height="16" transform="translate(771 822)"/><g transform="translate(9 -0.276)"><g transform="translate(762 823.276)"><g transform="translate(0 0)"><g transform="translate(0 0)"><path d="M54.81,16.9a3.41,3.41,0,1,0-.845-4.754A3.414,3.414,0,0,0,54.81,16.9Z" transform="translate(-49.084 -10.689)"/><path d="M6.768,133.459,3.414,117.312H0l3.405,16.651a8.528,8.528,0,0,0,8.432,7.246H23.9v-3.414H11.829A5.121,5.121,0,0,1,6.768,133.459Z" transform="translate(0 -108.781)"/><path d="M92.9,130.986H84.57l-1.75-7c2.688,1.519,5.6,2.637,8.79,2.082v-3.627a10.5,10.5,0,0,1-8.005-2.125L80.8,118.133a3.96,3.96,0,0,0-3-.751l-.043.009a3.841,3.841,0,0,0-3.124,4.446l2.313,10.1a5.12,5.12,0,0,0,5.027,4.165H93.667l6.52,5.121,2.56-2.56Z" transform="translate(-68.748 -108.8)"/></g></g></g></g></g></g></svg>',
      text: TEXTS.PASSENGERS_NUMBER,
    },
    numberOfPilots: {
      icon:
        '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 34 34"><defs><style>.a{fill:#fff;}</style></defs><g transform="translate(-771 -345)"><g transform="translate(0 -538)"><rect class="a" width="16" height="16" transform="translate(771 883)"/><g transform="translate(771 740.469)"><path d="M33.963,151.8a.843.843,0,0,0-.628-.639l-11.586-2.186a25.674,25.674,0,0,0-9.5,0L.664,151.16a.842.842,0,0,0-.627.635,1.047,1.047,0,0,0,.2.945L5.2,158.5v4.737s0,.005,0,.008a1.078,1.078,0,0,0,.014.159c0,.011,0,.022,0,.032a1.066,1.066,0,0,0,.031.125l.012.036v0c.188.539,2.189,5.532,11.741,5.532s11.554-4.993,11.742-5.532v0l.012-.036a1.068,1.068,0,0,0,.031-.125c0-.011,0-.022,0-.032a1.085,1.085,0,0,0,.014-.159s0-.005,0-.008v-4.858l4.954-5.629A1.047,1.047,0,0,0,33.963,151.8Zm-23.091.419H14.4a.767.767,0,0,1,.659.42A2.153,2.153,0,0,1,19,152.557a.746.746,0,0,1,.605-.339h3.526a.942.942,0,0,1,0,1.859h-.977c0,.013,0,.025,0,.038a.868.868,0,0,1-.789.93h-.729a.852.852,0,0,1-.783.85H19.6a.747.747,0,0,1-.605-.339,2.153,2.153,0,0,1-3.939-.081.766.766,0,0,1-.659.42h-.251a.852.852,0,0,1-.783-.85h-.729a.868.868,0,0,1-.789-.929c0-.013,0-.025,0-.038h-.977a.942.942,0,0,1,0-1.859ZM27.225,162.3H6.774v-3.331H27.225ZM16.3,154.057a.761.761,0,1,1,.751.884A.827.827,0,0,1,16.3,154.057Z" transform="translate(0 0)"/></g></g></g></svg>',
      text: TEXTS.PILOTS_NUMBER,
    },
    numberOfBeds: {
      icon:
        '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 34.8 34.815"><defs><style>.a{stroke:#000;stroke-width:0.8px;}</style></defs><g transform="translate(-770.6 -416.6)"><g transform="translate(771 417)"><g transform="translate(0)"><path class="a" d="M32.3,30.533a1.7,1.7,0,0,0-1.7,1.7v1.7H12.268a1.963,1.963,0,0,0,.2-.85A1.983,1.983,0,0,0,10.483,31.1H6.517a1.983,1.983,0,0,0-1.983,1.983,1.963,1.963,0,0,0,.2.85H3.4V27.7a1.7,1.7,0,1,0-3.4,0V43.567a1.7,1.7,0,0,0,3.3.567H30.7a1.7,1.7,0,0,0,3.3-.567V32.233A1.7,1.7,0,0,0,32.3,30.533Zm-1.7,9.633H10.767A.567.567,0,0,1,10.2,39.6V35.067H30.6ZM5.667,33.083a.85.85,0,0,1,.85-.85h3.967a.85.85,0,1,1,0,1.7H6.517A.85.85,0,0,1,5.667,33.083ZM1.7,44.133a.567.567,0,0,1-.567-.567V27.7a.567.567,0,0,1,1.133,0V43.567A.567.567,0,0,1,1.7,44.133Zm1.7-9.067H9.067V39.6a1.7,1.7,0,0,0,1.7,1.7H30.6V43H3.4Zm29.467,8.5a.567.567,0,0,1-1.133,0V32.233a.567.567,0,0,1,1.133,0Z" transform="translate(0 -11.267)"/><path class="a" d="M23.067,18.133A9.067,9.067,0,1,0,14,9.067,9.067,9.067,0,0,0,23.067,18.133Zm0-17a7.933,7.933,0,1,1-7.933,7.933A7.933,7.933,0,0,1,23.067,1.133Z" transform="translate(-6.067)"/><g transform="translate(10.359 2.281)"><g transform="translate(1.825 1.825)"><path class="a" d="M165.151,218.89h-2.408v-2.408a.482.482,0,0,0-.482-.482h-2.89a.482.482,0,0,0-.482.482v2.408h-2.408a.482.482,0,0,0-.482.482v2.89a.482.482,0,0,0,.482.482h2.408v2.408a.482.482,0,0,0,.482.482h2.89a.482.482,0,0,0,.482-.482v-2.408h2.408a.482.482,0,0,0,.482-.482v-2.89A.482.482,0,0,0,165.151,218.89Zm-.482,2.89h-2.408a.482.482,0,0,0-.482.482v2.408h-1.926v-2.408a.482.482,0,0,0-.482-.482h-2.408v-1.926h2.408a.482.482,0,0,0,.482-.482v-2.408h1.926v2.408a.482.482,0,0,0,.482.482h2.408Z" transform="translate(-156 -216)"/></g></g></g></g></g></svg>',
      text: TEXTS.TOTAL_BEDS,
    },
    numberOfMedicalCrew: {
      icon:
        '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 34.8 34"><defs><style>.a{fill:#fff;}.b{stroke:#000;stroke-width:0.8px;}</style></defs><g transform="translate(-768.6 -489)"><g transform="translate(0 -462)"><rect class="a" width="16" height="16" transform="translate(769 951)"/><g transform="translate(769 936)"><g transform="translate(10.359 29.281)"><g transform="translate(1.825 1.825)"><path class="b" d="M165.151,218.89h-2.408v-2.408a.482.482,0,0,0-.482-.482h-2.89a.482.482,0,0,0-.482.482v2.408h-2.408a.482.482,0,0,0-.482.482v2.89a.482.482,0,0,0,.482.482h2.408v2.408a.482.482,0,0,0,.482.482h2.89a.482.482,0,0,0,.482-.482v-2.408h2.408a.482.482,0,0,0,.482-.482v-2.89A.482.482,0,0,0,165.151,218.89Zm-.482,2.89h-2.408a.482.482,0,0,0-.482.482v2.408h-1.926v-2.408a.482.482,0,0,0-.482-.482h-2.408v-1.926h2.408a.482.482,0,0,0,.482-.482v-2.408h1.926v2.408a.482.482,0,0,0,.482.482h2.408Z" transform="translate(-156 -216)"/></g></g><g transform="translate(0 16)"><g transform="translate(0 0)"><path class="b" d="M30.68,23.969H25.633V19.984A3.989,3.989,0,0,0,21.648,16h-9.3a3.989,3.989,0,0,0-3.984,3.984v3.984H3.32A3.324,3.324,0,0,0,0,27.289V44.555a3.324,3.324,0,0,0,3.32,3.32H30.68A3.324,3.324,0,0,0,34,44.555V27.289A3.324,3.324,0,0,0,30.68,23.969ZM9.7,19.984a2.659,2.659,0,0,1,2.656-2.656h9.3A2.659,2.659,0,0,1,24.3,19.984v3.984H22.977v-3.32a1.994,1.994,0,0,0-1.992-1.992H13.016a1.994,1.994,0,0,0-1.992,1.992v3.32H9.7Zm11.953.664v3.32h-9.3v-3.32a.665.665,0,0,1,.664-.664h7.969A.665.665,0,0,1,21.648,20.648ZM4.383,46.547H3.32a1.994,1.994,0,0,1-1.992-1.992V27.289A1.994,1.994,0,0,1,3.32,25.3H4.383Zm23.906,0H26.961V33.93a.664.664,0,1,0-1.328,0V46.547H8.367V33.93a.664.664,0,1,0-1.328,0V46.547H5.711V25.3H7.039v3.32a.664.664,0,1,0,1.328,0V25.3H25.633v3.32a.664.664,0,1,0,1.328,0V25.3h1.328Zm4.383-1.992a1.994,1.994,0,0,1-1.992,1.992H29.617V25.3H30.68a1.994,1.994,0,0,1,1.992,1.992Z" transform="translate(0 -16)"/></g></g></g></g></g></svg>',
      text: TEXTS.MEDICAL_CREW,
    },
  };

  const formattedValue = types[type].formatValue
    ? types[type].formatValue(value)
    : value;

  return (
    <div className={Style.item}>
      <p className={Style.label}>
        <i dangerouslySetInnerHTML={{ __html: types[type].icon }} />
        {types[type].text}
      </p>
      <p>{formattedValue}</p>
    </div>
  );
};

const AircraftCharacteristics = ({ title, items }) => {
  return (
    <div>
      <p className={Style.title}> {title} </p>
      {items.map(
        item =>
          item.value > 0 && (
            <CharacteristicItem
              key={item.type}
              type={item.type}
              value={item.value}
            />
          )
      )}
    </div>
  );
};

export default AircraftCharacteristics;
