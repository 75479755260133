// Third party libs/components
import React from 'react';

// Components
import * as Styled from './FlightLabelsDetails.styles';

// Utils
import { format, parseISO } from 'date-fns';
import { translate as t, useCurrentLocale } from '@utils/translate';

const FlightLabelDetails = ({ item }) => {
  const locale = useCurrentLocale();
  const departureDateTime = format(parseISO(item.departure), "EEEE', 'PPPp", {
    locale: locale,
  });
  const TEXTS = {
    FLIGHT: t('flight'),
    AIRCRAFT: t('aircraft'),
    ORIGIN: t('origin'),
    DESTINATION: t('destination'),
    DEPARTURE: t('departure'),
  };

  return (
    <Styled.TooltipWrapper>
      <span className={'title'}>
        {TEXTS.FLIGHT} {item.flightNumber + 1}
      </span>
      <span className={'info'}>
        {TEXTS.AIRCRAFT}: <strong className={'value'}>{item.aircraft}</strong>
      </span>
      <span className={'info'}>
        {TEXTS.ORIGIN}: <strong className={'value'}>{item.origin}</strong>
      </span>
      <span className={'info'}>
        {TEXTS.DESTINATION}:{' '}
        <strong className={'value'}>{item.destination}</strong>
      </span>
      <span className={'info'}>
        {TEXTS.DEPARTURE}:{' '}
        <strong className={'value'}>{departureDateTime}</strong>
      </span>
    </Styled.TooltipWrapper>
  );
};

export default FlightLabelDetails;
