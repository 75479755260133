import styled from 'styled-components';
import { COLORS } from '@utils/styles/colors';

export const ProposalCardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;

  transition: all ease 0.5s;
`;

export const ProposalTab = styled.div`
  position: absolute;
  top: -50px;
  left: 15px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: auto;
  height: 50px;

  padding: 20px;

  box-shadow: 0px 3px 20px #00000029;
  border-radius: 15px 15px 0px 0px;
  background-color: white;
  font-size: 20px;
  font-weight: 600;

  /* z-index: 2; */

  @media (max-width: 720px) {
    top: -40px;
    height: 40px;

    font-size: 14px;
    font-weight: 600;
  }
`;

export const ProposalLabel = styled.div`
  position: absolute;
  top: -50px;
  right: 15px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: auto;
  height: 50px;

  padding: 20px;

  box-shadow: 0px 3px 20px #00000029;
  border-radius: 15px 15px 0px 0px;
  background-color: #26c3ae;
  color: white;
  font-size: 20px;
  font-weight: 600;

  /* z-index: 2; */

  img {
    margin-right: 10px;
  }

  span {
    margin-top: 3px;
  }

  @media (max-width: 720px) {
    top: -40px;
    height: 40px;

    font-size: 14px;
    font-weight: 600;
  }
`;

export const ProposalContentWrapper = styled.div`
  width: 100%;
  height: auto;

  background: #f5f5f5 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #00000029;
  border-radius: 15px 15px 0px 15px;
  margin-bottom: 50px;
  opacity: 1;

  /* z-index: 3; */

  @media (max-width: 720px) {
    border-radius: 15px;
  }
`;

export const BottomFixedInfo = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999999;

  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 80px;

  background-color: white;
  box-shadow: 0px 2px 20px #00000029;

  padding-left: var(--container-size);
  padding-right: var(--container-size);

  > div {
    display: flex;
    align-items: center;
    width: 50%;
  }

  .origin-destination {
    justify-content: flex-start;

    img {
      max-width: 12px;
      margin: 0 5px;
    }

    > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin-right: 40px;
    }

    span {
      font-size: 18px;
      color: #009688;
      font-weight: bold;
    }
  }
`;
