// TODO: TRANSLATION PATTERN
// TODO: REFACTORING
import React, { useEffect, useState, useContext, forwardRef } from 'react';
import * as Styled from './Amenities.style';
import AnimatedTooltip from '@components/AnimatedComponents/AnimatedTooltip';

import { translate as t } from '@utils/translate';

const HiddenAmenities = ({ items }) => {
  return (
    <Styled.HiddenAmenitiesWrapper>
      {items.map((item, index) => {
        return (
          <div key={index}>
            <img src={item.iconImageUrl} />
            <span>{item.name}</span>
          </div>
        );
      })}
    </Styled.HiddenAmenitiesWrapper>
  );
};

const Amenities = forwardRef(
  (
    {
      amenities,
      limitToShow,
      showTitle = true,
      breakLine = false,
      imgSize = 2,
      theme = 'default',
    },
    amenityRef
  ) => {
    const [validAmenities, setValidAmenities] = useState([]);
    const [hiddenAmenities, setHiddenAmenities] = useState([]);
    const TEXTS = {
      TITLE: t('availableAmenities'),
    };

    useEffect(() => {
      let hidden = [];

      hidden = amenities.filter((amenity, index) => {
        return index >= limitToShow;
      });
      setHiddenAmenities(hidden);
    }, [amenities, limitToShow]);

    useEffect(() => {
      let filteredAmenities = [];
      if (amenities) {
        filteredAmenities = amenities.filter(amenity => amenity.isActive);
        setValidAmenities([...filteredAmenities]);
      }
    }, [amenities]);

    return (
      <Styled.Amenities>
        {showTitle ? <Styled.Label>{TEXTS.TITLE}</Styled.Label> : null}
        <Styled.IconsWrapper theme={theme} flexWrap={breakLine} size={imgSize}>
          {validAmenities.map((amenity, index) => {
            return index < limitToShow ? (
              <div ref={index === 0 ? amenityRef : null} key={amenity.id}>
                <AnimatedTooltip label={amenity.name} aria-label={amenity.name}>
                  <img src={amenity.iconImageUrl} />
                </AnimatedTooltip>
              </div>
            ) : null;
          })}
          {hiddenAmenities.length > 0 ? (
            <AnimatedTooltip
              label={<HiddenAmenities items={hiddenAmenities} />}
            >
              <Styled.Plus theme={theme}>+{hiddenAmenities.length}</Styled.Plus>
            </AnimatedTooltip>
          ) : null}
        </Styled.IconsWrapper>
      </Styled.Amenities>
    );
  }
);

export default Amenities;
