// TODO: REFACTORING
import React, { useState, useEffect } from 'react';
import * as Styled from './ProposalCard.style';
import { useStore } from 'react-redux';
import { sprintf } from 'sprintf-js';

import ProposalCardDetail from './ProposalCardDetail';
import Money from '@images/money.svg';

import { translate as t } from '@utils/translate';
import { formatCurrency } from '@utils/currency';
import { setSelectedProposal } from '@store/reducers/proposals/operations';

const ProposalCard = ({
  proposal,
  aircraftsDetails,
  showOperatorCurrency,
  index,
  isPending,
  status,
  bestPrice = false,
  onAccept,
  handleLogin,
}) => {
  const store = useStore();
  const [segments, setSegments] = useState([]);
  const TEXTS = {
    OPTION_TITLE: t('optionN'),
    BEST_PRICE: t('bestPrice'),
  };

  useEffect(() => {
    if (proposal.segments) {
      let notEmptyLegs = proposal.segments.filter(segment => {
        return segment.emptyLeg === 0;
      });
      setSegments(notEmptyLegs);
    }
  }, []);

  const getValue = () => {
    const totals = {
      current: null,
      operator: null,
    };

    totals.current = formatCurrency({
      value: proposal.totalAmountAdjusted,
      currency: proposal.currency,
      showCurrencySymbol: false,
    }).trim();

    if (showOperatorCurrency && proposal.localCurrency !== proposal.currency) {
      totals.operator = formatCurrency({
        value: proposal.localTotalAmountAdjusted,
        currency: proposal.localCurrency,
        showCurrencySymbol: false,
      }).trim();
    }

    return totals;
  };

  const getCurrency = () => {
    const currencies = {
      current: proposal.currency,
      operator: proposal.localCurrency,
    };

    return currencies;
  };

  const handleAcceptProposal = () => {
    try {
      store.dispatch(setSelectedProposal(proposal));
      onAccept();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Styled.ProposalCardContainer>
        <Styled.ProposalTab>
          {sprintf(TEXTS.OPTION_TITLE, index + 1)}
        </Styled.ProposalTab>
        {bestPrice && (
          <Styled.ProposalLabel>
            <img src={Money} />
            <span>{TEXTS.BEST_PRICE}</span>
          </Styled.ProposalLabel>
        )}

        <Styled.ProposalContentWrapper>
          <ProposalCardDetail
            proposalItem={proposal}
            aircraftsDetails={aircraftsDetails}
            segments={segments}
            value={getValue()}
            currency={getCurrency()}
            onAcceptProposal={handleAcceptProposal}
            status={status}
            isSelectable={isPending}
            handleLogin={handleLogin}
          />
        </Styled.ProposalContentWrapper>
      </Styled.ProposalCardContainer>
    </>
  );
};

export default ProposalCard;
