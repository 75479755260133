import styled, { keyframes, css } from 'styled-components';
import { switchProp } from 'styled-tools';

const animationIn = keyframes`
  0% { 
    left: 50px;
    opacity: 0; 
  }
  
  100% { 
    left: 0; 
    opacity: 1; 
  }
`;

const PaymentType = styled.div`
  * {
    font-family: 'Montserrat';
    margin: 0;
    padding: 0;
  }

  animation: ${animationIn} 0.5s forwards;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 10px;
  justify-content: center;
  width: 35em;
  height: 100%;
  margin: auto;
  position: relative;
  top: -4em;
`;

const Title = styled.h1`
  font-size: 2.2em;
  width: 100%;
`;

const Description = styled.div`
  margin: 0 0 ${({ marginBottom }) => (marginBottom ? marginBottom : '2em')};
  width: 100%;

  h2 {
    font-size: 2.6em;
    font-weight: 400;
  }

  p {
    color: #0000004d;
    font-size: 1rem;
    font-weight: 400;
    padding: 0.5em 0;
  }
`;

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ActionButton = styled.button`
  border: none;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  background-color: #ffffff;
  cursor: pointer;
  padding: 0.9em 0;
  text-align: left;
  width: 100%;
  position: relative;

  &:active {
    color: initial;
  }

  &:hover {
    background-color: #faf7f7;
  }

  &:last-of-type {
    border-top: none;
  }

  &:after {
    content: '';
    position: absolute;
    width: 1em;
    height: 1em;
    border: 1px solid #333333;
    border-radius: 2px;
    right: 1em;
    top: 50%;
    transform: translateY(-50%);

    ${switchProp('selected', {
      true: css`
        background-color: #009688;
        border-color: #009688;
      `,
    })}
  }

  span {
    font-size: 1.1rem;
  }
`;

const CurrencySelector = styled.div`
  display: ${({ isVisible }) => (!isVisible ? 'none' : 'block')};
  margin: 30px 0;
  width: 100%;
`;

export {
  PaymentType,
  Title,
  Description,
  ActionsWrapper,
  ActionButton,
  CurrencySelector,
};
