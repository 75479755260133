import styled, { css, keyframes } from 'styled-components';

const COLORS = {
  darkGrey: '#858585',
  disabledGrey: '#E0E0E0',
  buttonGreen: '#009688',
  buttonHoverGreen: '#01BEA4',
  lightGrey: '#616161',
  overlayDark: '#33333322',
  panelShadow: '#0000004D',
  primaryGreen: '#009688',
  white: '#ffffff',
};

const overlayAnimationIn = keyframes`
  0% { 
    backdrop-filter: blur(0) brightness(100%);
    opacity: 0;
  }
  100% { 
    backdrop-filter: blur(2px) brightness(90%);
    opacity: 1;
  }
`;

const FinishProposal = styled.div`
  animation: ${overlayAnimationIn} 0.3s 0s forwards;
  font-size: 10px;
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1000000;
`;

const panelAnimationIn = keyframes`
  0% { height: 0px }
  100% { height: 600px }
`;

const ModalPanel = styled.div`
  animation: ${panelAnimationIn} 0.3s 0s forwards;
  background-color: ${COLORS.white};
  box-shadow: 0px 3px 30px ${COLORS.panelShadow};
  height: 400px;
  overflow: hidden;
  position: relative;
`;

const CloseButton = styled.div`
  cursor: pointer;
  height: 26px;
  position: absolute;
  right: 101px;
  top: 40px;
  width: 26px;
  z-index: 10;

  &:hover {
    &:before,
    &:after {
      background-color: ${COLORS.primaryGreen};
    }
  }

  &:after,
  &:before {
    background-color: ${COLORS.lightGrey};
    content: '';
    display: inline-block;
    height: 2px;
    left: 0;
    position: absolute;
    transform: rotate(-45deg);
    top: 11px;
    width: 100%;
  }

  &:after {
    transform: rotate(45deg);
  }
`;

const NavButtons = styled.div`
  bottom: 5em;
  display: flex;
  justify-content: flex-end;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 65em;
`;

const buttonDisabledTheme = css`
  background-color: ${COLORS.disabledGrey};
  border: 2px solid ${COLORS.disabledGrey};
  pointer-events: none;
  color: ${COLORS.darkGrey};
`;

const buttonActiveTheme = css`
  background-color: ${COLORS.buttonGreen};
  border: 2px solid ${COLORS.buttonGreen};
  color: ${COLORS.white};

  &:hover {
    background-color: ${COLORS.buttonHoverGreen};
    border-color: ${COLORS.buttonHoverGreen};
  }
`;

const getButtonTheme = theme => {
  return {
    disabled: buttonDisabledTheme,
    active: buttonActiveTheme,
  }[theme];
};

const ButtonNext = styled.button`
  background-color: #009688;
  border: none;
  border-radius: 8px;
  color: #ffffff;
  cursor: pointer;
  font-size: 1.5em;
  font-weight: 600;
  padding: 15px 0;
  transition: background-color 0.15s ease-in-out;
  width: 165px;

  ${({ theme }) => getButtonTheme(theme)};
`;

const ButtonPrev = styled.button`
  background-color: #ffffff;
  border: none;
  color: #009688;
  cursor: pointer;
  font-size: 1.5em;
  font-weight: 600;
  margin-right: 10px;
  padding: 15px 30px;
`;

const ErrorMessage = styled.p`
  width: 100%;
  text-align: center;
  color: red;
  position: absolute;
  top: -5em;
`;

export {
  FinishProposal,
  ModalPanel,
  CloseButton,
  NavButtons,
  ButtonPrev,
  ButtonNext,
  ErrorMessage,
};
