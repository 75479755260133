// react/third-part modules
import React, { useEffect, useState } from 'react';
import { Router } from '@reach/router';
import { useStore } from 'react-redux';

// component modules
import Layout from '@components/layout';
import Proposals from '../app/Proposals';

// contexts/hooks/others
import { setLanguage, setCurrency } from '@store/reducers/general/operations';
import { getCurrencyFromLocalStorage, getCurrencyParam } from '@utils/currency';
import { getLangFromLocalStorage, getLangParam } from '@utils/language';
import { getCurrencyByCode } from '@gatsby-local-plugin/flapper-gatsby-currencies/';

const ProposalPage = ({ location }) => {
  const path = location.pathname;
  const store = useStore();
  const urlParams = new URLSearchParams(location.search);
  const translations = [
    { path: path, polylang_current_lang: 'en_US' },
    { path: path, polylang_current_lang: 'es_ES' },
    { path: path, polylang_current_lang: 'pt_BR' },
    // { path: path, polylang_current_lang: 'it_IT' },
  ];
  const [mustLogIn, setMustLogIn] = useState(false);
  const [loginCallback, setLoginCallback] = useState(null);

  // get/set language
  const lang = urlParams.get('lang');
  if (lang) store.dispatch(setLanguage(lang));

  useEffect(() => {
    // get/set currency
    const currency = getCurrencyParam() || getCurrencyFromLocalStorage();
    const language = getLangParam() || getLangFromLocalStorage();
    store.dispatch(setCurrency(getCurrencyByCode(currency)));
    if (!lang) store.dispatch(setLanguage(language));
  }, []);

  const handleLogin = (mustLogIn, callback) => {
    setMustLogIn(mustLogIn);
    if (callback) setLoginCallback(() => callback);
  };

  const handleCloseLogin = () => {
    if (loginCallback) {
      setMustLogIn(false);
      loginCallback();
    } else setMustLogIn(false);
  };

  return (
    <Layout
      SearchHeader={false}
      translations={translations}
      useTranslationsPath={false}
      solidHeader="white"
      showNewsletter={false}
      showFooter={true}
      onOpenLogin={mustLogIn}
      closeLogin={handleCloseLogin}
      showLocaleOptions={true}
      blockLocaleOptions={{ currency: true, language: false }}
    >
      <Router basepath="/proposals">
        <Proposals path="/:hash" handleLogin={handleLogin} />
      </Router>
    </Layout>
  );
};

export default ProposalPage;
