import styled, { css } from 'styled-components';

export const ProposalContainer = styled.div`
  position: static;
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: calc(100% - (var(--container-size) * 2));
  height: auto;

  margin-top: calc(var(--header-height));
  margin-left: var(--container-size);
  margin-right: var(--container-size);

  font-family: 'Montserrat';
  font-weight: 500;

  @media (max-width: 720px) {
    width: 100vw;
    margin-top: calc(var(--header-height));
    padding-left: var(--container-size);
    padding-right: var(--container-size);

    margin-left: 0;
    margin-right: 0;

    overflow: hidden;
  }
`;

export const BackButtonWrapper = styled.div`
  padding: 20px 0px 50px 0px;
`;

export const ProposalsTitle = styled.h3`
  color: black;
  font-size: 22px;
`;

export const ProposalsDescription = styled.div`
  color: #757575;
  font-size: 20px;
  padding-bottom: 30px;

  p {
    text-align: justify;
    margin-bottom: 18px;

    &:nth-child(2) {
      text-indent: 20px;
    }
  }

  @media (max-width: 720px) {
    font-size: 16px;
  }
`;

export const ProposalsListWrapper = styled.div`
  margin: 0;
`;

export const ObservationsWrapper = styled.div`
  margin: 0 0 16px;
`;

export const PaymentOptionModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(4px);

  z-index: 9999;

  .payment-option-modal__content {
    width: 100%;
    height: 70%;
    background-color: white;
    box-shadow: 0px 3px 20px #00000029;

    z-index: 2;
  }
`;
