// TODO: REFACTORING
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import BillingAddress from '@components/BillingAddress';
import SavedAddresses from '@components/SavedAddresses';
import { getAddresses } from '@store/reducers/address/operations';
import { isLoggedIn } from '@services/auth';
import * as Styled from './FinishProposalUserAddress.styles';
import { translate as t } from '@utils/translate';

function FinishProposalUserAddress({ onAdd, initialData }) {
  const TEXTS = {
    INVOICE_TITLE: t('invoiceTitle'),
    INVOICE_SUBTITLE: t('invoiceSubtitle'),
  };
  const dispatch = useDispatch();
  const initialState = {
    address: '',
    cityName: '',
    complement: '',
    countryName: '',
    name: '',
    number: '',
    stateName: '',
    zipCode: '',
  };
  const { addresses } = useSelector(state => state.address);
  const [addressPicked, setAddressPicked] = useState(false);
  const [addressAdded, setAddressAdded] = useState(initialData);

  useEffect(() => {
    if (isLoggedIn()) {
      dispatch(getAddresses());
    }
  }, []);

  useEffect(() => {
    if (addressAdded && typeof addressAdded === 'object') {
      onAdd(addressAdded);
    }
  }, [addressAdded]);

  function handlePickAddress(id) {
    if (id) {
      setAddressPicked(id);
      onAdd(id);
    } else {
      setAddressPicked(false);

      typeof initialData === 'object'
        ? onAdd(initialData)
        : onAdd(initialState);

      if (typeof addressAdded === 'object') {
        onAdd(addressAdded);
      }
    }
  }

  function handleAddressChange(data) {
    setAddressAdded(data);
  }

  return (
    <Styled.UserAddress>
      <Styled.SavedAddressWrapper>
        {addresses && addresses.length ? (
          <SavedAddresses data={addresses} onPick={handlePickAddress} />
        ) : null}
      </Styled.SavedAddressWrapper>
      <BillingAddress
        initialAddress={initialData}
        isDisabled={!!addressPicked}
        title={TEXTS.INVOICE_TITLE}
        subtitle={TEXTS.INVOICE_SUBTITLE}
        theme="proposal"
        onCancel={() => null}
        onSave={() => null}
        onUpdate={() => null}
        onChange={handleAddressChange}
      />
    </Styled.UserAddress>
  );
}

export default FinishProposalUserAddress;
