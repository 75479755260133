import {
  apiHost,
  acceptProposal as acceptProposalApi,
  enableMockup,
} from '@utils/constants';
import { PROPOSAL } from '@mockup/accept.proposal.mockup';
import useRequest from '@utils/useRequest';

const MOCKUP = enableMockup && false;

/**
 * Accept proposal
 *
 * @param {object} selectedProposal The selected proposal object
 * @param {object} proposalData The object containing all form data
 */
function acceptProposal(selectedProposal, proposalData) {
  const {
    id: quotationProposalItemId,
    quotationProposalId: id,
    owner,
  } = selectedProposal;

  const {
    paymentType: paymentMethodSelectedId,
    paymentCurrency: userPaymentCurrency,
    userAddress: address,
    userData: { name: fullName, document: documentNumber, email },
  } = proposalData;

  const postData = {
    quotationProposal: {
      id,
      createCart: 1,
      userPaymentCurrency,
      owner: {
        ...owner,
        emailAddress: email,
      },
      quotationProposalItemId,
      paymentMethodSelectedId,
      invoice: {
        fullName,
        documentTypeId: 1,
        documentNumber,
        address,
      },
    },
  };

  return new Promise((resolve, reject) => {
    useRequest({
      url: `${apiHost}${acceptProposalApi}`,
      mockup: MOCKUP ? PROPOSAL : null,
      method: 'post',
      useSession: false,
      data: postData,
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export default acceptProposal;
