import styled from 'styled-components';
import { COLORS } from '@utils/styles/colors';

export const PriceWrapper = styled.div`
  > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  .price--main {
    font-size: 24px;
    font-weight: 600;

    em {
      font-style: normal;
      font-weight: 500;
      margin-right: 10px;
    }
  }

  .price--secondary {
    font-size: 17px;
    font-weight: 300;

    em {
      font-style: normal;
    }

    strong {
      font-weight: normal;
    }
  }

  @media (max-width: 720px) {
    > div {
      flex-direction: column;
    }
  }
`;
