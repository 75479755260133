// TODO: REFACTORING
import React, { useState } from 'react';
import CreditCardInput from '@components/CreditCardInput';
import * as Styled from './FinishProposalUserData.styles';
import { useEffect } from 'react';
import { translate as t } from '@utils/translate';

function FinishProposalUserData({ onChange, initialData }) {
  const TEXTS = {
    INVOICE_TITLE: t('invoiceTitle'),
    INVOICE_SUBTITLE: t('invoiceSubtitle'),
    CPF_CNPJ: 'CPF/CNPJ',
  };
  const [data, setData] = useState(initialData);

  useEffect(() => {
    onChange(data);
  }, [data]);

  function handleChange(evt) {
    const { name, value } = evt.target;

    setData(oldState => ({
      ...oldState,
      [name]: value,
    }));
  }

  return (
    <Styled.UserData>
      <Styled.Form>
        <Styled.Title>{TEXTS.INVOICE_TITLE}</Styled.Title>
        <Styled.Subtitle>{TEXTS.INVOICE_SUBTITLE}</Styled.Subtitle>
        <CreditCardInput>
          <input
            onChange={evt => handleChange(evt)}
            tabIndex="6"
            name="name"
            type="text"
            value={data.name}
            placeholder=" "
            maxLength="25"
          />
          <span>Nome completo</span>
        </CreditCardInput>
        <CreditCardInput>
          <input
            onChange={evt => handleChange(evt)}
            tabIndex="6"
            name="email"
            type="text"
            value={data.email}
            placeholder=" "
            maxLength="25"
            readOnly={!(data.id == '' || data.id == null)}
          />
          <span>E-mail</span>
        </CreditCardInput>
        {initialData.documentRequired ? (
          <CreditCardInput>
            <input
              onChange={evt => handleChange(evt)}
              tabIndex="6"
              name="document"
              type="text"
              value={data.document}
              placeholder=" "
              maxLength="25"
            />
            <span>{TEXTS.CPF_CNPJ}</span>
          </CreditCardInput>
        ) : null}
      </Styled.Form>
    </Styled.UserData>
  );
}

export default FinishProposalUserData;
