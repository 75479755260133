import styled, { css } from 'styled-components';
import { switchProp } from 'styled-tools';

export const Amenities = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  img {
    max-width: 30px;
    margin: 0 5px;
  }
`;

export const Label = styled.span`
  color: grey;
  padding-bottom: 10px;
  margin-left: 5px;
`;

export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  transition: filter 1s ease-in;
  flex-wrap: ${({ flexWrap }) => (flexWrap ? 'wrap' : 'unset')};

  flex-wrap: ${({ flexWrap }) => (flexWrap ? 'wrap' : 'unset')};
  > img {
    ${({ flexWrap }) =>
      flexWrap
        ? css`
            margin-bottom: 10px;
          `
        : ''};

    ${switchProp('size', {
      1: css`
        max-width: 15px;
        margin-right: 10px;
        height: 20px;
      `,
      2: css`
        max-width: 30px;
        margin-right: 10px;
        height: 30px;
      `,
      3: css`
        max-width: 50px;
        margin-right: 20px;
        height: 40px;
      `,
    })}
  }

  img {
    ${switchProp('theme', {
      default: css`
        filter: grayscale(1);
      `,
      green: css`
        filter: grayscale(0);
      `,
      white: css`
        filter: invert(100%) sepia(70%) saturate(0%) brightness(160%)
          contrast(100%);
      `,
    })}
  }
`;

export const Plus = styled.span`
  cursor: default;
  line-height: 100%;
  font-size: 21px;
  font-weight: 600;
  margin-left: 10px;
  transition: color 1s ease-in;

  ${switchProp('theme', {
    default: css`
      color: #666;
    `,
    green: css`
      color: #009688;
    `,
    white: css`
      color: #fff;
    `,
  })}
`;

export const HiddenAmenitiesWrapper = styled.div`
  > div {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  > div > img {
    max-width: 17px;
    margin-right: 10px;
    filter: invert(100%) sepia(70%) saturate(0%) brightness(160%) contrast(100%);
  }
`;
