import styled from 'styled-components';

const COLORS = {
  darkGrey: '#858585',
  disabledGrey: '#E0E0E0',
  buttonGreen: '#009688',
  buttonHoverGreen: '#01BEA4',
  lightGrey: '#616161',
  overlayDark: '#33333322',
  panelShadow: '#0000004D',
  primaryGreen: '#009688',
  white: '#ffffff',
};

const Title = styled.h1`
  font-size: 2.2em;
  width: 100%;
`;

const Description = styled.p`
  color: #0000008a;
  font-size: 1rem;
  font-weight: 400;
  padding: 0.5em 0 !important;
  text-align: left;
  width: 100%;
`;

const ActionWrapper = styled.div`
  margin-top: 2em;
  width: 100%;
`;

const Button = styled.button`
  background-color: #009688;
  border: none;
  border-radius: 8px;
  color: #ffffff;
  cursor: pointer;
  font-size: 1.5em;
  font-weight: 600;
  padding: 15px 0 !important;
  transition: background-color 0.15s ease-in-out;
  width: 100%;

  &:hover {
    background-color: ${COLORS.buttonHoverGreen};
    border-color: ${COLORS.buttonHoverGreen};
  }
`;

const TransferInfoBlock = styled.div`
  text-align: left;
  background-color: #f2f2f2;
  border-radius: 3px;
  padding: 15px !important;
  font-size: 14px;
  width: 100%;
  margin: 5px 0 !important;

  span {
    display: block;
    margin: 3px 0 !important;
  }
`;

export { Title, Description, ActionWrapper, Button, TransferInfoBlock };
