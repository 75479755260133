import { Creators } from './actions';
import useRequest from '@utils/useRequest';

import { apiHost } from '@utils/constants';

export const setProposalsData = Creators.setProposalsData;
export const setAircraftsDetails = Creators.setAircraftsDetails;
export const setSelectedProposal = Creators.setSelectedProposal;
export const reset = Creators.reset;

export const fetchProposals = (hash, lang) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      useRequest({
        url: `${apiHost}/api/v1/quotation/proposal/getbyhash?hash=${hash}`,
        method: 'get',
        useSession: false,
        customHeaders: {
          'Accept-Language': lang,
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          if (err.response && err.response.data) {
            console.error(err.response.data.result.message);
            reject(err.response.data);
          } else reject(err);
        });
    });
  };
};

export const fetchAircraftDetails = ids => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      useRequest({
        url: `${apiHost}/api/v1/aircraft/get?id=${ids.toString()}&bringAmenities=1&bringCertifications=1&bringAircraftSize=1`,
        method: 'get',
        useSession: false,
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  };
};

export default {
  fetchProposals,
  setProposalsData,
  reset,
};
