import styled, { keyframes } from 'styled-components';

const animationIn = keyframes`
  0% { 
    left: 50px;
    opacity: 0; 
  }
  
  100% { 
    left: 0; 
    opacity: 1; 
  }
`;

const Login = styled.div`
  animation: ${animationIn} 0.5s forwards;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  opacity: 0;
`;

const InnerWrapper = styled.div`
  width: 40em;
  font-size: 10px;
`;

const Header = styled.header`
  * {
    margin: 0;
    padding: 0;
  }

  h1 {
    font-size: 2.4em;
    font-weight: 400;
    margin-bottom: 0.5em;
  }

  h2 {
    font-size: 1.6em;
    font-weight: 400;
  }

  margin-bottom: 3em;
`;

export { Login, InnerWrapper, Header };
