import styled, { keyframes, css } from 'styled-components';
import { COLORS } from '@utils/styles/colors';

export const SegmentContentAircraft = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  padding-left: 20px;

  .category {
    font-size: 14px;
    font-weight: 600;
    color: #00998a;
  }

  .model {
    font-size: 24px;
    font-weight: 600;
    color: #707070;
  }

  .seats {
    font-size: 16px;
    color: #707070;
  }

  @media (max-width: 720px) {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 5px;
    border-radius: 8px 8px 0 0px;
    background-color: rgba(0, 0, 0, 0.5);
    background: transparent
      linear-gradient(181deg, #00000000 0%, #00000048 19%, #000000 100%) 0% 0%
      no-repeat padding-box;

    .category {
      font-size: 11px;
      font-weight: bold;
      color: white;
    }

    .model {
      font-size: 16px;
      font-weight: 600;
      color: white;
    }

    .seats {
      font-size: 14px;
      color: #dfdfdf;
    }
  }
`;

export const SegmentContentAmenities = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: hidden;
  padding-left: 20px;
`;

export const SegmentContentCertifications = styled.div`
  width: 30%;

  @media (max-width: 720px) {
    width: 60%;
    padding: 6px;
    margin-top: 10px;
    border: 1px solid #dfe7ea;
    border-radius: 8px;

    > img {
      width: 63px;
    }

    > div {
      margin: 0;
      padding: 0;
      min-height: auto;
    }
  }
`;

export const SegmentContentSlide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
  height: auto;

  padding: 20px 0px 0px 0px;

  .aircraft--wrapper {
    position: relative;
    width: 100%;
  }

  .certifications-amenities--wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    > div {
      width: 100%;
      padding: 10px;
    }
  }
`;

export const SliderWrapper = styled.div`
  .slick-list {
    overflow: initial !important;
  }

  .slick-slide {
    padding-right: 10px;
    padding-left: 10px;

    transition: all ease 0.3s;
  }

  .slick-slide.slick-current {
    /* padding-right: 0px;
    padding-left: 0px; */
  }

  .slick-list,
  .slick-slider,
  .slick-track {
    position: initial;
    ${({ isModalOpen }) =>
      isModalOpen ? 'transform: initial !important' : ''};
  }
`;
