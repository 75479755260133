import styled, { keyframes, css } from 'styled-components';
import { COLORS } from '@utils/styles/colors';

export const SegmentHeaderImage = styled.img`
  width: auto;
  height: 110px;
  border-radius: 15px;

  @media (max-width: 720px) {
    width: 100%;
    height: auto;
    border-radius: 5px;
  }
`;

export const SegmentHeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 80%;
  margin: 10px;
  color: white;
  font-size: 1.2rem;

  .step-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    em,
    strong {
      font-style: normal;
      margin-right: 10px;
    }
  }

  .origin-destination {
    display: flex;
    align-items: center;
  }

  @media (max-width: 720px) {
    width: 100%;
    margin: 0px;
    padding: 10px;

    .step-info {
      display: flex;
      flex: 1;
      justify-content: space-evenly;
      align-items: center;
      margin-bottom: 10px;
      white-space: nowrap;

      strong {
        display: none;
      }

      em {
        font-size: 17px;
        padding: 5px 17px;
        border-radius: 17px;
        color: #666;
        background: #f3f3f3 0% 0% no-repeat padding-box;
        margin: 0;
        overflow-x: hidden;
        text-overflow: ellipsis;
      }
    }

    .origin-destination {
      margin-bottom: 20px;
      justify-content: center;

      .origin-name {
        overflow-x: hidden;
        white-space: nowrap;
        font-weight: 600;
        font-size: 17px;
        color: #000000;
        transition: all ease 0.3s;
      }

      .origin-name--hover:hover {
        text-indent: -100%;
      }
    }

    .departure-date {
      color: #a3a3a3;
      font-size: 14px;
      text-align: center;
      &::first-letter {
        text-transform: uppercase;
      }
    }
  }

  @media (min-width: 720px) and (max-width: 1024px) {
    margin: 5px 10px;
    font-size: 1.1rem;
  }
`;

export const SegmentContentAircraft = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  padding-left: 20px;

  .category {
    font-size: 14px;
    font-weight: 600;
    color: #00998a;
  }

  .model {
    font-size: 24px;
    font-weight: 600;
    color: #707070;
  }

  .seats {
    font-size: 16px;
    color: #707070;
  }

  @media (max-width: 720px) {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 5px;
    border-radius: 8px 8px 0 0px;
    background-color: rgba(0, 0, 0, 0.5);
    background: transparent
      linear-gradient(181deg, #00000000 0%, #00000048 19%, #000000 100%) 0% 0%
      no-repeat padding-box;

    .category {
      font-size: 11px;
      font-weight: bold;
      color: white;
    }

    .model {
      font-size: 16px;
      font-weight: 600;
      color: white;
    }

    .seats {
      font-size: 14px;
      color: #dfdfdf;
    }
  }
`;
