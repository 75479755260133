// TODO: REFACTORING
import React, { useState, useRef, useEffect } from 'react';
import * as Styled from './SegmentContent.style';
import Slider from 'react-slick';
import { sprintf } from 'sprintf-js';

import Loader from '@components/Loader';
import Certifications from '@components/Certifications';
import Amenities from '@components/Amenities';
import SegmentHeader from '../SegmentHeader';
import SegmentDetailed from '../SegmentDetailed';
import aircraftNameHelper from '@utils/aircraftNameHelper';

const SegmentContentDesktop = ({ aircraftId, aircraftsDetails, seatsText }) => {
  const [isFetching, setIsFetching] = useState(true);
  const hasCertifications = !!(
    aircraftsDetails[aircraftId] &&
    aircraftsDetails[aircraftId].certifications.length
  );
  const hasAmenities = !!(
    aircraftsDetails[aircraftId] &&
    aircraftsDetails[aircraftId].amenities.length
  );

  useEffect(() => {
    setTimeout(() => {
      setIsFetching(false);
    }, 300);
  }, [aircraftsDetails]);

  if (!aircraftsDetails[aircraftId]) return null;

  return (
    <>
      {isFetching ? (
        <>
          <Loader
            customStyles={{
              height: '50px',
              backgroundColor: 'transparent',
              position: 'relative',
            }}
          ></Loader>
        </>
      ) : (
        <>
          <Styled.SegmentContentAircraft>
            <span className={'category'}>
              {aircraftsDetails[aircraftId].model.aircraftSize.name}
            </span>
            <span className={'model'}>
              {aircraftNameHelper(aircraftsDetails[aircraftId])}
            </span>
            <span className={'seats'}>
              {sprintf(
                seatsText,
                aircraftsDetails[aircraftId].quantityOfPassengers
              ).toUpperCase()}
            </span>
          </Styled.SegmentContentAircraft>
          {hasCertifications && (
            <Styled.SegmentContentCertifications>
              <Certifications
                certifications={
                  aircraftsDetails[aircraftId]
                    ? aircraftsDetails[aircraftId].certifications
                    : []
                }
                governmentOnly={true}
              />
            </Styled.SegmentContentCertifications>
          )}
          {hasAmenities && (
            <Styled.SegmentContentAmenities>
              <Amenities
                amenities={aircraftsDetails[aircraftId].amenities}
                limitToShow={hasCertifications ? 4 : 10}
              />
            </Styled.SegmentContentAmenities>
          )}
        </>
      )}
    </>
  );
};

const SegmentContentMobile = ({
  segments,
  aircraftsDetails,
  onSlideChange,
  currentSlide,
  seatsText,
  getCurrentStep,
  getFlightDuration,
  getDeparture,
  moreDetails,
}) => {
  const sliderRef = useRef(null);
  const [isFetching, setIsFetching] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false); // necessary to fix the modal transform issue (see begin of file)
  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (current, next) => onSlideChange(current),
  };

  useEffect(() => {
    if (sliderRef && sliderRef.current)
      sliderRef.current.slickGoTo(currentSlide);
  }, [currentSlide]);

  useEffect(() => {
    setTimeout(() => {
      setIsFetching(false);
    }, 300);
  }, [aircraftsDetails]);

  const handleIsModalOpen = active => {
    setIsModalOpen(active);
  };

  return (
    <>
      {isFetching ? (
        <>
          <Loader
            customStyles={{
              height: '50px',
              backgroundColor: 'transparent',
              position: 'relative',
            }}
          ></Loader>
        </>
      ) : (
        <>
          <Styled.SliderWrapper isModalOpen={isModalOpen}>
            {moreDetails ? (
              <>
                {segments.map((segment, index) => (
                  <SegmentDetailed
                    key={index}
                    index={index}
                    route={segment}
                    aircraftsDetails={aircraftsDetails}
                    routesCount={segments.length}
                    isOpen={moreDetails}
                  />
                ))}
              </>
            ) : (
              <Slider {...settings} ref={sliderRef}>
                {segments.map((segment, index) => {
                  const aircraftId = segment.aircraft.id;
                  const hasCertifications = !!(
                    aircraftsDetails[aircraftId] &&
                    aircraftsDetails[aircraftId].certifications.length
                  );
                  const hasAmenities = !!(
                    aircraftsDetails[aircraftId] &&
                    aircraftsDetails[aircraftId].amenities.length
                  );
                  return (
                    <div className={'segment__wrapper--mobile'} key={index}>
                      <Styled.SegmentContentSlide>
                        <SegmentHeader
                          isMobile={true}
                          segment={segment}
                          aircraftsDetails={aircraftsDetails}
                          stepText={getCurrentStep(index)}
                          seatsText={seatsText}
                          getFlightDuration={getFlightDuration}
                          getDeparture={getDeparture}
                        ></SegmentHeader>
                        <div className={'certifications-amenities--wrapper'}>
                          {hasCertifications && (
                            <Styled.SegmentContentCertifications>
                              <Certifications
                                certifications={
                                  aircraftsDetails[aircraftId]
                                    ? aircraftsDetails[aircraftId]
                                        .certifications
                                    : []
                                }
                                governmentOnly={true}
                                onClickDetails={() => handleIsModalOpen(true)}
                                onCloseDetails={() => handleIsModalOpen(false)}
                              />
                            </Styled.SegmentContentCertifications>
                          )}
                          {hasAmenities && (
                            <Styled.SegmentContentCertifications>
                              <Amenities
                                amenities={
                                  aircraftsDetails[aircraftId].amenities
                                }
                                limitToShow={5}
                              />
                            </Styled.SegmentContentCertifications>
                          )}
                        </div>
                      </Styled.SegmentContentSlide>
                    </div>
                  );
                })}
              </Slider>
            )}
          </Styled.SliderWrapper>
        </>
      )}
    </>
  );
};

const SegmentContent = ({
  isMobile,
  segments,
  aircraftId,
  aircraftsDetails,
  onSlideChange,
  currentSlide,
  seatsText,
  getCurrentStep,
  getFlightDuration,
  getDeparture,
  moreDetails,
}) => {
  if (isMobile) {
    return (
      <SegmentContentMobile
        segments={segments}
        aircraftsDetails={aircraftsDetails}
        onSlideChange={onSlideChange}
        currentSlide={currentSlide}
        seatsText={seatsText}
        getCurrentStep={getCurrentStep}
        getFlightDuration={getFlightDuration}
        getDeparture={getDeparture}
        moreDetails={moreDetails}
      />
    );
  } else {
    return (
      <SegmentContentDesktop
        aircraftId={aircraftId}
        aircraftsDetails={aircraftsDetails}
        seatsText={seatsText}
      />
    );
  }
};

export default SegmentContent;
