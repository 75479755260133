import styled, { keyframes, css } from 'styled-components';
import { switchProp } from 'styled-tools';
import { COLORS } from '@utils/styles/colors';

const exitAnimation = keyframes`
  0% { 
    left: 0%;
    opacity: 1;
  }
  100% { 
    left: -100%;
    opacity: 0;
  }
`;

const enterAnimation = keyframes`
  0% { 
    left: -10%;
    opacity: 0;
  },
  100% { 
    left: 0%;
    opacity: 1;
  }
`;

export const ProposalCardDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  width: 100%;
  padding: 0px 20px 20px 20px;

  transition: all ease 0.5s;

  z-index: 1;

  @media (max-width: 720px) {
    padding: 0px 2px 20px 2px;
  }
`;

export const ProposalCardDetailHeader = styled.div`
  position: relative;
  top: 0%;
  left: -20px;
  width: calc(100% + 40px);
  height: 120px;

  padding: 20px 40px;

  box-shadow: 0px 3px 20px #00000029;
  border-radius: 15px;
  background-color: white;

  z-index: 3;

  .header__wrapper {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    height: 100%;

    hr {
      margin: 10px 0;
      padding: 0;
    }

    .header__path {
      display: flex;
      flex-direction: column;
      width: 50%;
    }

    .header__price {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 50%;
      color: #010101;
      justify-content: flex-start;

      > * {
        margin-left: 20px;
      }
    }
  }

  @media (max-width: 720px) {
    left: -10px;
    width: calc(100% + 20px);

    padding: 10px 20px;
    height: auto;

    .header__wrapper {
      .header__path {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
    }
  }
`;

export const ProposalCardDetailSegmentsContainer = styled.div`
  width: 100%;
  height: auto;

  hr {
    margin: 20px 0;
    padding: 0;
  }

  .proposal-card-footer--wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-top: 20px;
  }

  .proposal-card-footer {
    display: flex;
    flex-direction: column;
    margin: 0px 0px;
    padding: 0 20px 0px 20px;

    button {
      margin-right: 20px;
    }
  }

  @media (max-width: 720px) {
    min-height: 400px;

    .proposal-card-footer--wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      span {
        margin: 5px 0;
        font-size: 24px;
      }
    }

    .proposal-card-footer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;

      > div {
        width: 100%;
        text-align: center;
      }

      button {
        width: 100%;
        margin-right: 0px;
        margin-top: 20px;
      }
    }
  }
`;

export const ProposalCardDetailSegment = styled.div`
  width: 100%;
  height: auto;

  /* z-index: 1; */

  .segment__wrapper {
    position: relative;
    padding-top: 45px;
    min-height: 130px;

    left: 0%;
    opacity: 1;

    ${switchProp('moreDetails', {
      true: css`
        position: absolute;
        animation: ${exitAnimation} 1.3s 0s;
        opacity: 0;
      `,
      false: css`
        animation: ${enterAnimation} 1.3s 0s;
      `,
    })}
  }

  .segment__wrapper--mobile {
    position: static;
    width: 100%;
    height: auto;

    margin-top: -20px;
    padding: 10px;

    border-radius: 10px;
    box-shadow: 0px 3px 20px #00000029;
    background-color: white;

    /* z-index: 1; */
  }

  .segment__header-wrapper {
    position: absolute;
    top: 0;
    width: calc(100% - 40px);
    height: 130px;

    margin-left: 20px;
    padding: 10px;

    border-radius: 15px;
    box-shadow: 0px 3px 20px #00000029;
    background-color: #00998a;

    opacity: 1;

    /* z-index: 2; */
  }

  .segment__header {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .segment__content-wrapper {
    position: static;
    width: 80%;
    height: auto;

    margin-top: 0px;
    padding: 95px 10px 10px 10px;

    border-radius: 26px 0px 124px 23px;
    box-shadow: 0px 3px 20px #00000029;
    background-color: #f3f3f3;

    /* z-index: 1; */
  }

  .segment__content {
    display: flex;
    min-height: 50px;
  }

  .segment__content-details-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 20px;
    padding: 0 20px 0px 20px;

    .path-text {
      font-size: 16px;
      font-weight: 700;
      color: #009788;
      margin-bottom: 3px;
      text-transform: uppercase;
    }
  }

  .segment__content-details {
    .cardHeader {
      display: none;
    }
  }

  &:first-of-type {
    .segment__wrapper {
      padding-top: 0px;
    }

    .segment__header-wrapper {
      border-radius: 0px 0px 46px 15px;
    }

    .segment__content-wrapper {
      margin-top: 0px;
      border-radius: 0px 0px 104px 23px;
      padding: 140px 10px 10px 10px;
    }
  }
`;

export const SectionTitle = styled.h2`
  font-size: 26px;
  font-weight: bold;
  color: #212121;
`;

export const TextArea = styled.iframe`
  width: 100%;
  border: 2px solid #ddd;
  background-color: white;
  min-height: 400px;

  @media (max-width: 720px) {
    min-height: auto;
    height: 100%;
  }
`;

export const RightArrow = styled.span`
  margin: 0 10px;
`;

export const Warning = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 84px;
  width: 100%;

  padding: 20px;
  margin: 20px 0;
  border-radius: 8px;

  background: transparent
    linear-gradient(
      90deg,
      ${COLORS.CELADON_GREEN} 0%,
      ${COLORS.PERSIAN_GREEN} 100%
    )
    0% 0% no-repeat padding-box;
  color: white;
  font-size: 21px;
  font-weight: 500;

  &:before {
    content: '!';
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    background-color: white;
    color: ${COLORS.PERSIAN_GREEN};
    font-weight: bold;
  }

  @media (max-width: 720px) {
    padding: 15px;
    font-size: 13px !important;
    font-weight: 500;

    &:before {
      display: none;
    }
  }
`;

export const AgreementModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 0 10px;
  height: 100%;
  width: 100%;

  background-color: white;

  z-index: 99999;

  > span {
    margin: 0;
  }

  .header__wrapper {
    margin-bottom: 10px;
    color: ${COLORS.PERSIAN_GREEN};
  }

  .mobile-agreement-doc {
    height: 100%;
    flex: 1;

    > div {
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      height: 100%;

      > h2 {
        font-size: 17px;
        text-align: center;
      }
    }
  }

  .mobile-agreement-buttons {
    display: flex;
    padding: 10px;
    max-height: 20%;

    > button {
      font-size: 15px;
      margin: 0 10px;
    }
  }
`;

export const ObservationsWrapper = styled.div`
  margin: 16px 0 8px;
  padding: 0 20px;
`;
