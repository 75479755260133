// TODO: REFACTORING
/**
 * There's an issue using 'position: fixed' in a child when its parent has 'transform'.
 * To fix that, I've forced the 'transform: initial' in the slick-track class when certifications modal is opened.
 */
import React, { useEffect, useState, useRef, createRef } from 'react';
import * as Styled from './ProposalCardDetail.style';
import { format, parseISO } from 'date-fns';
import { useSelector } from 'react-redux';
import { withBreakpoints } from 'gatsby-plugin-breakpoints';

import ActionBar from './ActionBar';
import Button from './Button';
import Agreement from './Agreement';
import ProposalCardFooter from './ProposalCardFooter';
import Header from './Header';
import SegmentHeader from './SegmentHeader';
import SegmentContent from './SegmentContent';
import SegmentDetailed from './SegmentDetailed';
import Price from './Price';

import Snackbar from '@components/Snackbar';

import { translate as t, useCurrentLocale } from '@utils/translate';

const ProposalCardDetail = ({
  proposalItem,
  segments,
  value,
  currency,
  breakpoints,
  onAcceptProposal,
  status,
  aircraftsDetails,
  handleLogin,
}) => {
  const proposalData = useSelector(state => state.proposals.proposalData);
  const locale = useCurrentLocale();
  const [initialSegment, setInitialSegment] = useState(null);
  const [lastSegment, setLastSegment] = useState(null);
  const [isMobile, setIsMobile] = useState(breakpoints['sm']);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [moreDetails, setMoreDetails] = useState(false);
  const [agreement, setAgreement] = useState('');
  const [agreementAccepted, setAgreementAccepted] = useState(false);
  const [agreementModalOpen, setAgreementModalOpen] = useState(false);
  const [paymentData, setPaymentData] = useState(null);

  const agreementRef = useRef();
  const proposalHeaderRef = useRef();
  const [segmentsRefs, setSegmentsRefs] = useState([]);
  const TEXTS = {
    ONE_WAY: t('oneWay'),
    ROUND_TRIP: t('roundTrip'),
    MULTIPATH: t('multileg'),
    BUTTON_BACK_LABEL: t('back'),
    BUTTON_ACCEPT_PROPOSAL_LABEL: t('acceptProposal'),
    STEPS: {
      START: t('firstSegment'),
      BETWEEN: t('intermediateSegment'),
      END: t('lastSegment'),
      ROUND_BACK: t('returningSegment'),
    },
    SEATS: `%d ${t('seats')}`,
    WARNING: `${t('Atenção')}: ${t(
      'Após aceitar a proposta não será possível escolher outra opção'
    )}`,
  };

  useEffect(() => {
    if (segments) {
      setInitialSegment(segments[0]);
      setLastSegment(segments[segments.length - 1]);
    }
  }, [segments]);

  useEffect(() => {
    setSegmentsRefs(segmentsRefs =>
      Array(segments.length)
        .fill()
        .map((_, i) => segmentsRefs[i] || createRef())
    );
  }, [segments]);

  useEffect(() => {
    setIsMobile(breakpoints['sm']);
  }, [breakpoints]);

  const checkRoundTrip = () => {
    const originCity = initialSegment.originSpot.address.city.name;
    const finalCity = lastSegment.destinationSpot.address.city.name;

    if (segments.length >= 2 && originCity === finalCity) return true;
  };

  const getPathCountInfo = () => {
    if (segments.length >= 2) {
      if (checkRoundTrip()) return TEXTS.ROUND_TRIP;
      else return TEXTS.MULTIPATH;
    } else return TEXTS.ONE_WAY;
  };

  const getCurrentStep = step => {
    if (step === 0) return TEXTS.STEPS.START;
    if (step === segments.length - 1) {
      if (checkRoundTrip()) return TEXTS.STEPS.ROUND_BACK;
      else return TEXTS.STEPS.END;
    } else return TEXTS.STEPS.BETWEEN;
  };

  const getFlightDuration = segment => {
    const time = segment.estimatedTime;
    const hours = Math.floor(time / 60);
    const minutes = time - 60 * hours;

    return `${hours ? hours + 'h' : ''} ${minutes ? minutes + 'min' : ''}`;
  };

  const getDeparture = segment => {
    return format(parseISO(segment.departureDatetime), "EEEE', 'PPPp", {
      locale: locale,
    });
  };

  const handleSlideChange = index => {
    setCurrentSlide(index);
  };

  const handleClickNavButton = index => {
    setCurrentSlide(index);
  };

  const handleMoreDetails = active => {
    setMoreDetails(active);
    setTimeout(() => {
      if (active) scrollToRef(proposalHeaderRef, 70);
      else scrollToRef(proposalHeaderRef, -140);
    }, 100);
  };

  const handleToggleAcceptAgreement = () => {
    setAgreementAccepted(!agreementAccepted);
  };

  const handleAgreementModalOpen = active => {
    setAgreementModalOpen(active);
  };

  /**
   * Scrolls the window to ref's position
   * @param {object} ref
   * @param {number} additionalPixels
   */
  const scrollToRef = (ref, additionalPixels) => {
    if (ref.current) {
      window.scrollTo({
        behavior: 'smooth',
        top: getOffsetTop(ref.current) + additionalPixels,
      });
    }
  };

  /**
   * Scrolls the window to indexed ref's position
   * @param {array} refs
   * @param {number} additionalPixels
   * @param {number} index
   */
  const scrollToRefByIndex = (refs, additionalPixels, index) => {
    try {
      const ref = refs[index];
      if (ref.current) {
        scrollToRef(ref, additionalPixels);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getOffsetTop = elem => {
    let offsetTop = 0;
    do {
      if (!isNaN(elem.offsetTop)) {
        offsetTop += elem.offsetTop;
      }
    } while ((elem = elem.offsetParent));
    return offsetTop;
  };

  useEffect(() => {
    if (proposalData) {
      if (status === 3) {
        setPaymentData({
          isCreditCard:
            proposalData.charterContract.paymentMethodSelected
              .paymentTypeKey === 'CreditCard',
          wireTrasferSetup:
            proposalData.charterContract.paymentMethodSelected[
              'wireTransferSetup'
            ],
        });
      }
      setAgreement(proposalData.legalAgreement.documentUrl);
    }
  }, [proposalData]);

  if (!initialSegment) return null;

  return (
    <>
      {!isMobile ? (
        <Styled.ProposalCardDetailContainer ref={proposalHeaderRef}>
          <Styled.ProposalCardDetailHeader
            justifyPrice={moreDetails ? 'flex-start' : 'space-evenly'}
          >
            <div className={'header__wrapper'}>
              <Header
                originSpot={initialSegment.originSpot}
                destinationSpot={
                  checkRoundTrip()
                    ? segments.length > 2
                      ? lastSegment.destinationSpot
                      : lastSegment.originSpot
                    : lastSegment.destinationSpot
                }
                value={value}
                currency={currency}
                pathcount={getPathCountInfo()}
                showButtonDetails={!moreDetails}
                onClickMoreDetails={() => handleMoreDetails(true)}
              ></Header>
            </div>
          </Styled.ProposalCardDetailHeader>
          <Styled.ProposalCardDetailSegmentsContainer>
            {moreDetails && proposalItem.observations && (
              <Styled.ObservationsWrapper>
                <Snackbar type="info" icon="info">
                  {proposalItem.observations}
                </Snackbar>
              </Styled.ObservationsWrapper>
            )}

            {segments.map((segment, index) => (
              <Styled.ProposalCardDetailSegment
                key={index}
                moreDetails={moreDetails}
                ref={segmentsRefs[index]}
              >
                {index > 0 ? <hr></hr> : ''}
                <div className={'segment__wrapper'}>
                  <div className={'segment__header-wrapper'}>
                    <div className={'segment__header'}>
                      <SegmentHeader
                        segment={segment}
                        stepText={getCurrentStep(index)}
                        getFlightDuration={getFlightDuration}
                        getDeparture={getDeparture}
                      ></SegmentHeader>
                    </div>
                  </div>
                  <div className={'segment__content-wrapper'}>
                    <div className={'segment__content'}>
                      <SegmentContent
                        aircraftId={segment.aircraft.id}
                        aircraftsDetails={aircraftsDetails}
                        seatsText={TEXTS.SEATS}
                      ></SegmentContent>
                    </div>
                  </div>
                </div>
                <SegmentDetailed
                  route={segment}
                  routesCount={segments.length}
                  index={index}
                  isOpen={moreDetails}
                  aircraftsDetails={aircraftsDetails}
                />
              </Styled.ProposalCardDetailSegment>
            ))}

            <div ref={agreementRef}>
              <Agreement
                contentUrl={agreement}
                show={moreDetails}
                accepted={agreementAccepted}
                handleToggleAccept={handleToggleAcceptAgreement}
                isSelectable={status === 2}
              />
            </div>
            {moreDetails && (
              <>
                <ProposalCardFooter
                  handleMoreDetails={handleMoreDetails}
                  handleAcceptProposal={onAcceptProposal}
                  showingMoreDetails={moreDetails}
                  agreementAccepted={agreementAccepted}
                  isAcceptable={status === 2}
                />
              </>
            )}
          </Styled.ProposalCardDetailSegmentsContainer>
        </Styled.ProposalCardDetailContainer>
      ) : (
        <Styled.ProposalCardDetailContainer ref={proposalHeaderRef}>
          <Styled.ProposalCardDetailHeader>
            <div className={'header__wrapper'}>
              <Header
                isMobile={true}
                segments={segments}
                originSpot={initialSegment.originSpot}
                destinationSpot={
                  checkRoundTrip()
                    ? segments.length > 2
                      ? lastSegment.destinationSpot
                      : lastSegment.originSpot
                    : lastSegment.destinationSpot
                }
                currentSlide={currentSlide}
                onClickNavButton={handleClickNavButton}
                showNav={!moreDetails}
              ></Header>
            </div>
          </Styled.ProposalCardDetailHeader>

          <Styled.ProposalCardDetailSegmentsContainer>
            {moreDetails && proposalItem.observations && (
              <Styled.ObservationsWrapper>
                <Snackbar type="info" icon="info">
                  {proposalItem.observations}
                </Snackbar>
              </Styled.ObservationsWrapper>
            )}

            <Styled.ProposalCardDetailSegment>
              <SegmentContent
                isMobile={true}
                segments={segments}
                aircraftsDetails={aircraftsDetails}
                currentSlide={currentSlide}
                onSlideChange={handleSlideChange}
                seatsText={TEXTS.SEATS}
                getCurrentStep={getCurrentStep}
                getFlightDuration={getFlightDuration}
                getDeparture={getDeparture}
                moreDetails={moreDetails}
              ></SegmentContent>
            </Styled.ProposalCardDetailSegment>

            <div className={'proposal-card-footer--wrapper'}>
              <Price values={value} currencies={currency} />
              <ProposalCardFooter
                isMobile={true}
                handleMoreDetails={handleMoreDetails}
                handleSeeAgreement={handleAgreementModalOpen}
                showingMoreDetails={moreDetails}
              />
            </div>
          </Styled.ProposalCardDetailSegmentsContainer>
        </Styled.ProposalCardDetailContainer>
      )}

      {!isMobile && moreDetails && status === 2 && (
        <ActionBar
          proposalStatus={status}
          segments={segments}
          value={value}
          currency={currency}
          onClick={() => scrollToRef(agreementRef, -70)}
          onClickFlightLabel={index =>
            scrollToRefByIndex(segmentsRefs, -70, index)
          }
        />
      )}

      {status === 3 && (
        <ActionBar
          proposalStatus={status}
          proposalId={proposalData.id}
          handleLogin={handleLogin}
          paymentData={paymentData}
          currency={currency}
        />
      )}

      {isMobile && agreementModalOpen && (
        <Styled.AgreementModal>
          <div className={'header__wrapper'}>
            <Header
              isMobile={true}
              segments={segments}
              originSpot={initialSegment.originSpot}
              destinationSpot={
                checkRoundTrip()
                  ? lastSegment.originSpot
                  : lastSegment.destinationSpot
              }
              currentSlide={currentSlide}
              onClickNavButton={handleClickNavButton}
              showNav={!moreDetails}
            ></Header>
          </div>
          <div className={'mobile-agreement-doc'}>
            <Agreement
              contentUrl={agreement}
              show={moreDetails}
              accepted={agreementAccepted}
              handleToggleAccept={handleToggleAcceptAgreement}
              isSelectable={status === 2}
            />
          </div>
          {status === 2 && <Styled.Warning>{TEXTS.WARNING}</Styled.Warning>}
          <div className={'mobile-agreement-buttons'}>
            <Button
              theme={'default'}
              label={TEXTS.BUTTON_BACK_LABEL}
              onClick={() => handleAgreementModalOpen(false)}
            />
            {status === 2 && (
              <Button
                theme={agreementAccepted ? 'active' : 'disabled'}
                label={TEXTS.BUTTON_ACCEPT_PROPOSAL_LABEL}
                onClick={onAcceptProposal}
              />
            )}
          </div>
        </Styled.AgreementModal>
      )}
    </>
  );
};

export default withBreakpoints(ProposalCardDetail);
