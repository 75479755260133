// TODO: REFACTORING
import React from 'react';
import * as Styled from './SegmentDetailed.style';
import { sprintf } from 'sprintf-js';

import PathCard from '@components/PathCard';

import AircraftInfo from '../AircraftInfo';

import { translate as t } from '@utils/translate';

const SegmentDetailed = ({
  isOpen,
  index,
  routesCount,
  route,
  aircraftsDetails,
}) => {
  const TEXTS = {
    PATH: t('segmentNofN'),
  };

  return (
    <>
      {isOpen && (
        <div className={'segment__content-details-wrapper'}>
          <p className={'path-text'}>
            {sprintf(TEXTS.PATH, index + 1, routesCount)}
          </p>
          <div className={'segment__content-details'}>
            <PathCard
              route={route}
              aircraftDetails={aircraftsDetails[route.aircraft.id]}
              flightsLenght={routesCount}
              modal={false}
              showSeparator={false}
              showNotice={false}
              showAmenities={false}
            />

            <div className={'aircraft-info--wrapper'}>
              <AircraftInfo
                aircraftId={route.aircraftId}
                aircraftsDetails={aircraftsDetails}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SegmentDetailed;
