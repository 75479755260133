// TODO: REFACTORING
import React, { useEffect, useRef } from 'react';
import * as Styled from './SegmentHeader.style';
import { sprintf } from 'sprintf-js';

import Arrow from '../Arrow';
import aircraftNameHelper from '@utils/aircraftNameHelper';

import { solveIataOaci } from '@utils/spots';

const SegmentHeaderDesktop = ({
  segment,
  stepText,
  getFlightDuration,
  getDeparture,
}) => {
  const aircraft = segment.aircraft;
  return (
    <>
      <Styled.SegmentHeaderImage
        src={aircraft.highlightImageUrl}
      ></Styled.SegmentHeaderImage>
      <Styled.SegmentHeaderInfo>
        <div className={'step-info'}>
          <em>{stepText}</em>
          <strong>•</strong>
          <em>{getFlightDuration(segment)}</em>
        </div>
        <div className={'origin-destination'}>
          <span className={'origin-name'}>
            {`${segment.originSpot.name} (${solveIataOaci(
              segment.originSpot,
              'both'
            )})`}
          </span>
          <Arrow direction={'right'} color={'white'}></Arrow>
          <span className={'destination-name'}>
            {`${segment.destinationSpot.name} (${solveIataOaci(
              segment.destinationSpot,
              'both'
            )})`}
          </span>
        </div>
        <span>{getDeparture(segment)}</span>
      </Styled.SegmentHeaderInfo>
    </>
  );
};

const SegmentHeaderMobile = ({
  segment,
  aircraftsDetails,
  stepText,
  seatsText,
  getFlightDuration,
  getDeparture,
}) => {
  const aircraft = segment.aircraft;
  const textContainerRef = useRef(null);
  const textContentRef = useRef(null);

  useEffect(() => {
    const toggleRouteInfoClass = () => {
      if (
        textContentRef.current.clientWidth >=
        textContainerRef.current.clientWidth
      ) {
        textContentRef.current.classList.add('origin-name--hover');
      } else {
        textContentRef.current.classList.remove('origin-name--hover');
      }
    };

    toggleRouteInfoClass();

    window.addEventListener('resize', toggleRouteInfoClass);

    return () => window.removeEventListener('resize', toggleRouteInfoClass);
  }, []);

  if (!aircraft) return null;
  return (
    <>
      <div className={'aircraft--wrapper'}>
        <Styled.SegmentHeaderImage
          src={aircraft.highlightImageUrl}
        ></Styled.SegmentHeaderImage>
        <Styled.SegmentContentAircraft>
          <span className={'category'}>
            {aircraftsDetails[aircraft.id] &&
              aircraftsDetails[aircraft.id].model.aircraftSize.name}
          </span>
          <span className={'model'}>{aircraftNameHelper(aircraft)}</span>
          <span className={'seats'}>
            {sprintf(seatsText, aircraft.quantityOfPassengers).toUpperCase()}
          </span>
        </Styled.SegmentContentAircraft>
      </div>
      <Styled.SegmentHeaderInfo>
        <div className={'step-info'}>
          <em>{stepText}</em>
          <strong>•</strong>
          <em>{getFlightDuration(segment)}</em>
        </div>
        <div className={'origin-destination'} ref={textContainerRef}>
          <div className={['origin-name'].join(' ')} ref={textContentRef}>
            {`(${solveIataOaci(segment.originSpot, 'both')})
              ➔
              (${solveIataOaci(segment.destinationSpot, 'both')})`}
          </div>
        </div>
        <span className={'departure-date'}>{getDeparture(segment)}</span>
      </Styled.SegmentHeaderInfo>
    </>
  );
};

const SegmentHeader = ({
  isMobile,
  segment,
  aircraftsDetails,
  stepText,
  seatsText,
  getFlightDuration,
  getDeparture,
}) => {
  if (isMobile) {
    return (
      <SegmentHeaderMobile
        segment={segment}
        aircraftsDetails={aircraftsDetails}
        stepText={stepText}
        getFlightDuration={getFlightDuration}
        getDeparture={getDeparture}
        seatsText={seatsText}
      />
    );
  } else {
    return (
      <SegmentHeaderDesktop
        segment={segment}
        stepText={stepText}
        getFlightDuration={getFlightDuration}
        getDeparture={getDeparture}
      />
    );
  }
};

export default SegmentHeader;
